body {
  font-family: Overpass !important;
  margin: 0px;
  background-color: #242e42;
}

.hidden {
  display: none;
}

.synthetic-option {
  &:hover{
    background-color: rgb(163, 208, 255);
    // color: #fff;
    cursor: pointer;
  }
}

.filter-radio {
  color: #fff !important;
  & +.filter-radio{
    margin-left: 10px;
  }
}

.react-datepicker-popper {
  z-index: 11;
}
.jNIFwL,
.fowQkw {
  z-index: 9999 !important;
}
.orders-control-view {
  & button {
    width: fit-content;
    min-width: 150px;
  }

  & .switches-container {
    display: flex;
    justify-content: space-evenly;
    width: 100%;

    & > div {
      width: 30%;
    }
  }
  & .content {
    & .view-actions {
      height: 75px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    & .resume-header {
      width: calc(85% - 50px);
      height: 70px;
      margin-right: 25px;
      margin-left: calc(14% + 25px);
      display: flex;
      align-items: center;

      & > div {
        width: 100%;
        text-align: center;
        font-weight: bold;
        font-size: 14px;
        color: rgb(255, 255, 255);
      }
    }

    & .orders-row-header {
      padding-left: 11px;
      height: 80px;
      // width: 1200px;
      border-radius: 6px 6px 0 0;
      background-color: #3d5074;
      display: flex;
      border-bottom: 2px #a4b4ce solid;
      box-sizing: border-box;
      margin: 0 2%;

      & > div {
        margin: auto 0 auto 0;
        text-align: center;
        color: #a4b4ce;
        font-size: 16px;
        line-height: 25px;
      }

      & .logo {
        width: 5%;
      }

      & .credentials {
        width: 15%;
      }

      & .user-type {
        width: 10%;
      }

      & .product-name {
        width: 36%;
      }

      & .products {
        width: 9%;
      }

      & .facilities {
        width: 20%;
      }

      & .tags-products {
        width: 28%;
      }

      & .tags {
        width: 17%;
      }

      & .account-status {
        width: 13%;
      }

      & .historical-data {
        width: 20%;
      }

      & .historical-details {
        width: 40%;
      }

      & .last-connection {
        width: 18%;
      }

      & .complete-table {
        width: 35% !important;
      }
    }

    & .shipment-row {
      & .last {
        border-top: 2px solid #ffffff80;
      }

      & .collapsable-order-row {
        background-color: rgb(34, 42, 62);
      }
    }
  }
}

.shipment-detail-view {
  & .scroll-test-traffic-row.timeline {
    &:hover {
      // overflow: visible !important;
    }
  }

  & .select-input {
    width: 130px;
  }
}

.traffic-view,
.no-pad-bottom {
  padding-bottom: 0px !important;
}

.small-pad-bottom {
  padding-bottom: 20px !important;
}

.billing-report-view {
  display: flex;
  flex-direction: column;
  padding-bottom: 10px !important;

  & .select-option {
    // width: 100%;
    text-align: right;
    color: #fff;
    font-weight: bold;

    & span {
      cursor: pointer;
    }
  }


  & .table-sortable {
    flex-grow: 1;
    max-height: fit-content;
    margin-top: 0;

    & .rows-container {
      flex-grow: 1;

      & .row {
        position: relative;

        & .selection-layer {
          position: absolute;
          width: 100%;
          height: 100%;
          background-color: #0000004d;
          // opacity: 0.6;
          cursor: pointer;

          & .checkbox {
            position: absolute;
            top: 11px;
            left: 11px;
            height: 20px;
            width: 20px;
            border-radius: 4px;
            background-color: #a7b6d6;
            transition: 0.3s ease;
          }

          &.selected {
            & .checkbox {
              background-color: #3d77f7;
              &::after {
                content: "";
                height: 4px;
                width: 10px;
                border-bottom: 3px solid #fff;
                border-left: 3px solid #fff;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -69%) rotate(-45deg);
              }
            }
          }

          &:hover {
            background-color: #1818184d;
            transition: 0.3s ease;
          }
        }

        & .row-content {
          height: 45px;
        }
      }
    }
  }

  & .filter-content .filter .list .content {
    left: 100%;
    top: 100%;
    transform: translate(-100%, 3px);
  }
}

.billing-cancellation-view {
  & .content {
    color: #fff;
    
    & .data {
      display: flex;
      margin: 20px 0;

      & > div {
        width: 100%;
      }
    }

    & .motives {
      width: 50%;
    }

    & .actions {
      margin: 30px 0;
      width: 100%;
      display: flex;
      justify-content: end;
    }
  }
}

.on-hover-tooltip {
  & .show{
    color: #000 !important;
    background-color: #fff !important;
  }
}

.home-dashboard {
  margin-left: -40px;
  background: initial !important;

  & .subscription-info {
    display: flex;
    color: #FFFFFF;
    // font-family: Overpass;
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 22px;

    & > div {
      margin-left: 20px;
    }

    & .current-subscription {
      font-size: 18px;

      & .date {
        opacity: 0.5;
        font-size: 12px;
      }
    }

    & .actions {
      & button {
        width: 200px;
      }
    }
  }

  & .content {
    margin-top: 30px;
    & .panels {
      & > div {
        display: flex;
        justify-content: space-between;

        &.upper {
          & .panel {
            height: 128px;
            & .panel-body .complimentary-info {
              height: 60px;
            }
          }
        }

        &.lower {
          & .panel {
            height: 200px;

            & .panel-title {
              margin-bottom: 20px;
            }

            & .value {
              font-size: 25px;
            }
          }
        }
      }

      & .panel {
        width: 30%;
        display: flex;
        flex-direction: column;
        padding-top: 5px;
        position: relative;
        box-shadow: 2px 2px 12px 1px #080a0c;
        width: 100%;
        margin: 0 15px 20px;

        & .divisor {
          height: 100px;
          width: 1px;
          background-color: #fff;
          position: relative;
          top: 15px;
        }

        & button {
          width: fit-content;
        }

        &.blurred {
          & .panel-title,
          & .panel-body {
            filter: blur(2px);
          }
        }

        & .overlay {
          position: absolute;
          top: 0;
          left: 0;
          height: 100%;
          width: 100%;
          border-radius: 12px;
          background-color: #000;
          opacity: .55;
          z-index: 2;
          color: #fff;
          display: flex;
          justify-content: center;
          align-items: center;
          text-align: center;
        }

        & .link {
          opacity: 0.8;
          color: #4A90E2;
          font-family: Overpass;
          font-size: 16px;
          letter-spacing: 0;
          line-height: 25px;
          cursor: pointer;
          text-align: center;

          & a {
            text-decoration: none;
          }
        }

        & .panel-title {
          height: 35px;
          color: #FFFFFF;
          font-size: 14px;
          font-weight: bold;
          letter-spacing: 0;
          display: flex;
          align-items: center;
          justify-content: space-between;

          & .extra-info {
            opacity: 0.8;
            color: #AFC8FF;
            font-family: Overpass;
            font-size: 18px;
            font-weight: bold;
            letter-spacing: 0;
            line-height: 28px;
          }
        }

        & .panel-body {
          flex-grow: 1;

          &.summary-container {
            display: flex;

            & .summary{
              padding: 0 15px;
              width: 30%;
            }
          }

          & .summary {
            &+ .summary {
              border-left: 2px solid #979797;
              & .summary-title {
                text-align: center;
                position: relative;
              }
            }

            & .summary-title {
              color: #AFC8FF;
              font-size: 16px;
              font-weight: bold;
              margin-bottom: 10px;

              position: relative;

              & .link-container {
                position: absolute;
                top: 0;
                right: 0;
              }
            }

            & .summary-content {
              display: flex;
              justify-content: space-around;
              min-height: 58.8px;
              height: 58.8px;
              align-items: center;

              & .summary-item {
                & .description {
                  color: #FFFFFF;
                  font-family: Overpass;
                  font-size: 12px;
                  letter-spacing: 0;
                  line-height: 18px;
                  text-align: center;
                  margin-bottom: 10px;
                }

                & .value {
                  text-align: center;
                }
              }
            }

            & .link-container {
              text-align: center;
              min-height: 25px;

            }

            
          }

          & .complimentary-info {
            height: 85px;
            background-color: #171E2C;
            margin-right: -16px;
            margin-left: -16px;
            border-radius: 0 0 12px 12px;
            display: flex;
            align-items: center;
            justify-content: center;

            & .summary {
              width: 100%;
            }

            & > .summary-item {
              display: flex;
              height: 100%;
              width: 50%;
              align-items: center;
              justify-content: center;

              & .description {
                width: 75px;
                opacity: 0.8;
                color: #AFC8FF;
                font-family: Overpass;
                font-size: 12px;
                letter-spacing: 0;
                line-height: 18px;
                margin-bottom: 0 !important;
              }
            }
          }
        }

        & .qty,
        & .value {
          // opacity: 0.8;
          color: #AFC8FF;
          font-weight: bold;
          font-size: 18px;;

          &.main {
            font-size: 40px;
            text-align: center;
            margin:5px 0;
          }

          &.medium {
            // text-align: center;
            padding: 7px 0;
            display: flex;
            justify-content: center;
          }
        }

        & .panel-actions {
          display: flex;
          justify-content: center;
          & button {
            height: 27px;
            &:focus {
              outline: none;
            }
          }
        }
      }
    }
  }
}

.catalog {
  background: linear-gradient(180deg, #6a7caf 0%, #242e42 40%);
  background-color: #242e42;
  padding: 0;
  // width: 100%;
  height: 100%;

  &.route-catalog-view {
    & .content {
      & .toggle-select-button {
        right: 3% !important;
      }
    }
  }

  & .header {
    // background: linear-gradient(180deg, #6A7CAF 0%, #242E42 100%);
    display: flex;
    width: 100%;
    text-align: center;
    height: 100px;
    align-items: center;

    & .title {
      width: 33.3%;
      color: #ffffff;
      font-size: 36px;
      font-weight: 800;
      line-height: 55px;
    }

    & .view-mode {
      // width: 33.3%;
      width: fit-content;
      margin: auto;
      display: flex;

      & .grid {
        margin: 9px;
        height: 36px;
        width: 36px;
        background-image: url("/images/grid-icon.svg");
        background-repeat: no-repeat;
        background-position: center;
        background-size: 20px;
        border-radius: 18px;
        border: 1px solid #79a3ff;
        cursor: pointer;
        transition: 0.2s;
        box-sizing: border-box;
      }

      & .grid:hover {
        background-image: url("/images/grid-icon-active.svg");
        background-color: #79a3ff;
      }

      & .grid.active {
        background-image: url("/images/grid-icon-active.svg");
        border: none;
        background-color: #79a3ff;
      }

      & .list {
        margin: 9px;
        height: 36px;
        width: 36px;
        background-image: url("/images/list-icon.svg");
        background-repeat: no-repeat;
        background-position: center;
        background-size: 20px;
        border-radius: 18px;
        border: 1px solid #79a3ff;
        cursor: pointer;
        transition: 0.2s;
        box-sizing: border-box;
      }

      & .list:hover {
        background-image: url("/images/list-icon-active.svg");
        background-color: #79a3ff;
      }

      & .list.active {
        background-image: url("/images/list-icon-active.svg");
        background-color: #79a3ff;
        border: none;
      }
    }

    & .action {
      // width: 33.3%;
      margin-right: 25px;
      display: flex;
      justify-content: center;

      & .btn {
        & .user-icon {
          position: relative;
          left: -14px;
        }

        & + .btn {
          margin-left: 20px;
        }
      }
    }
  }

  & > .content {
    padding: 0 0;
    margin-top: 35px;
    position: relative;

    & .catalog-row-header {
      height: 80px;
      border-radius: 12px 12px 0 0;
      background-color: #3d5074;
      display: flex;
      border-bottom: 2px #a4b4ce solid;
      box-sizing: border-box;

      & > div {
        margin: auto 0 auto 0;
        text-align: center;
        color: #a4b4ce;
        font-size: 16px;
        line-height: 25px;
      }

      &.network-row-header {
        margin: 0 2%;

        & .logo {
          width: 8%;
        }

        & .credentials {
          width: 27%;
        }

        & .invitations {
          width: calc(8.5% - 8px);
          min-width: calc(8.5% - 8px);
          max-width: calc(8.5% - 8px);
          padding-left: 4px;
          padding-right: 4px;
        }

        & .orbi-id {
          width: 15%;
        }

        & .joined-since {
          width: 25%;
        }

        & .company-type {
          width: 20%;
        }

        & .status {
          width: 25%;
        }

        & .actions {
          width: 5%;
        }
      }

      &.contracts-row-header {
        margin: 0 2%;

        & .facility {
          width: 25%;
        }

        & .trasport-line {
          width: 25%;
        }

        & .transport-class {
          width: 25%;
        }

        & .base {
          width: 12%;
        }

        & .units {
          width: 10%;
        }

        & .actions {
          width: 3%;
        }
      }
    }

    & .items-container {
      // margin: 0 59px 0 80px;
      margin: 0 2%;
    }

    & .toggle-select-button {
      position: absolute;
      top: 0px;
      right: 0px;
      transform: translateY(-25px);
    }

    & .facility-row-header {
      height: 91px;
      background-color: #3d5074;
      display: flex;
      color: #a4b4ce;
      font-size: 16px;
      font-weight: bold;
      border-bottom: 2px solid #a4b4ce;
      line-height: 91px;
      margin: 0 2%;
      border-radius: 7px 7px 0 0;

      & .logo {
        width: 7%;
      }

      & .credentials {
        width: 20%;
      }

      & .handling {
        width: 12%;
      }

      & .tags {
        width: 27%;
      }

      & .status {
        width: 13%;
      }

      & .geofences {
        width: 15%;
      }

      & .rfc {
        width: 5%;
      }

      & .operativeStatus{
        width: 17%;
      }
    }

    & .facility-row {
      height: 91px;
      background-color: #3d5074;
      // background-color: #fff;
      display: flex;
      position: relative;
      box-sizing: border-box;

      & + .facility-row {
        border-top: 1px solid #a4b4ce;
      }

      &:hover {
        background-color: #526a99;
      }

      & .logo {
        position: relative;
        width: 7%;

        & .avatar-container {
          width: 60px;
          height: 60px;
          border-radius: 60px;
          background-color: rgba(255, 255, 255, 0.98);
          position: relative;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);

          & img {
            // height: 30px;
            height: 30px;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          }
        }

        & img {
          height: 56px;
          position: absolute;
          left: 50%;
          top: 15%;
          transform: translateX(-50%);
          border-radius: 35px;
        }

        &.default {
          & img {
            border-radius: 0px;
          }
        }
      }

      & .credentials {
        width: 20%;
        padding: 10px 0;
        box-sizing: border-box;

        & .name {
          font-size: 14px;
          font-weight: bold;
          color: #fff;
        }

        & .code,
        & .handling {
          color: #afc8ff;
          font-size: 12px;
        }
      }

      & .rfc {
        width: 5%;
        display: flex;
        align-items: center;
      }

      & .handling {
        width: 12%;
        color: #afc8ff;
        font-size: 13px;
        padding: 39px 0;
        box-sizing: border-box;

        & img {
          margin: 0 5px 0 10px;

          &:first-child {
            margin-left: 0px;
            width: 55px;
          }

          &:last-child {
            width: 35px;
          }
        }
      }

      & .tags {
        width: 27%;
        color: #a4b4ce;
        font-size: 13px;
        padding: 32px 0;
        box-sizing: border-box;

        // & .tag-body {
        //   padding: 1px 4px;
        // }

        // span {
        //   color: #fff;
        //   background-color: #6988cd;
        //   border-radius: 4px;
        //   font-size: 12px;
        //   padding: 5px;
        //   margin: 3px;
        //   white-space: nowrap;

        //   &.hidden {
        //     display: none;
        //   }
        // }
      }

      & .status {
        width: 13%;
        color: #ffffff;
        font-size: 14px;
        font-weight: bold;
        padding: 40px 0;
        box-sizing: border-box;

        & img {
          margin-right: 10px;
        }
      }

      & .geofences {
        width: 15%;
        display: flex;
        padding: 36px 0;
        box-sizing: border-box;

        & div {
          width: 25px;
          height: 25px;
          border-radius: 15px;
          font-size: 13px;
          line-height: 28px;
          text-align: center;
          color: #fff;
          font-weight: bold;

          & + div {
            margin-left: 16px;
          }
        }

        & .fences {
          background-color: #55b740;
        }

        & .entry {
          background-color: #5eb5ff;
        }

        & .validation {
          color: #704904;
          background-color: #fff845;
        }

        & .ramps {
          background-color: #e9643f;
        }
      }

      &.selection {
        & .overlay {
          border-radius: 0px;
          box-sizing: border-box;
        }

        &:hover {
          transform: scale(1, 1);
        }
      }

      // &.selection-on {
      //   & .overlay {
      //     border-radius: 0px;
      //     box-sizing: border-box;
      //   }
      //   &:hover {
      //     transform: scale(1, 1);
      //   }
      // }
    }

    & .facility-card {
      & .top-row {
        border-bottom: 1px solid #444e63;
        height: 120px;
        display: flex;
        padding-top: 10px;
        box-sizing: border-box;
        position: relative;
        margin: 0 8px;

        & .logo {
          width: 33%;
          position: relative;

          & .avatar-container {
            width: 60px;
            height: 60px;
            border-radius: 70px;
            // background-color: #498ecb;
            background-color: rgba(255, 255, 255, 0.98);
            position: relative;
            top: 40%;
            left: 45%;
            transform: translate(-50%, -50%);

            & img {
              // height: 28px;
              height: 30px;
              position: absolute;
              // top: 50%;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
            }
          }

          & img {
            height: 56px;
            position: absolute;
            left: 50%;
            top: 15%;
            transform: translateX(-50%);
            border-radius: 35px;
          }

          &.default {
            & img {
              border-radius: 0px;
            }
          }
        }

        & .credentials {
          width: 67%;

          & .name {
            font-size: 14px;
            font-weight: bold;
            color: #fff;
          }

          & .code,
          & .handling {
            color: #afc8ff;
            font-size: 12px;

            & img {
              margin: 0 5px 0 10px;

              &:first-child {
                margin-left: 0px;
                width: 45px;
              }

              &:last-child {
                width: 25px;
              }
            }
          }
        }

        & > img {
          position: absolute;
          right: 10px;
          top: 15px;
        }
      }

      & .bottom-row {
        display: flex;
        height: 40px;
        box-sizing: border-box;
        width: 100%;
        margin: 0 8px;

        & .geofences {
          width: 28%;
          display: flex;
          align-items: center;

          & div {
            width: 20px;
            height: 20px;
            border-radius: 10px;
            font-size: 12px;
            line-height: 24px;
            text-align: center;
            color: #fff;
            font-weight: bold;

            & + div {
              margin-left: 6px;
            }
          }

          & .fences {
            background-color: #55b740;
          }

          & .entry {
            background-color: #5eb5ff;
          }

          & .validation {
            color: #704904;
            background-color: #fff845;
          }

          & .ramps {
            background-color: #e9643f;
          }
        }

        & .tags {
          width: auto;
          display: flex;
          color: #a4b4ce;
          font-size: 12px;
          overflow: hidden;
          height: 100%;
          align-items: center;
          white-space: nowrap;

          // & .tag-body {
          //   padding: 1px 5px;
          // }

          // span {
          //   color: #fff;
          //   background-color: #6988cd;
          //   border-radius: 4px;
          //   font-size: 12px;
          //   padding: 5px;
          //   margin: 3px;
          //   white-space: nowrap;

          //   &.hidden {
          //     display: none;
          //   }
          // }
        }
      }
    }

    & .select {
      position: absolute;
      top: 15px;
      right: 15px;
      color: #afc8ff;
      font-size: 14px;
      font-weight: bold;
      cursor: pointer;
    }

    & .user-row-header {
      // padding-left: 11px;
      height: 80px;
      // width: 1200px;
      border-radius: 6px 6px 0 0;
      background-color: #3d5074;
      display: flex;
      border-bottom: 2px #a4b4ce solid;
      box-sizing: border-box;
      margin: 0 2%;

      &.products-row {
        // & > div {
        //   border: 1px solid;
        // }
        & .products {
          width: 9%;
        }

        & .danger {
          width: 5%;
        }

        & .tags-products {
          width: 11%;
        }
      }

      & > div {
        margin: auto 0 auto 0;
        text-align: center;
        color: #a4b4ce;
        font-size: 16px;
        line-height: 25px;
      }

      & .logo {
        width: 5%;
      }

      & .credentials {
        width: 15%;
      }

      & .user-type {
        width: 10%;
      }

      & .product-name {
        width: 36%;
      }

      & .products {
        width: 9%;
      }

      & .facilities {
        width: 20%;
      }

      & .tags-products {
        width: 28%;
      }

      & .tags {
        width: 17%;
      }

      & .account-status {
        width: 13%;
      }

      & .historical-data {
        width: 20%;
      }

      & .historical-details {
        width: 40%;
      }

      & .last-connection {
        width: 18%;
      }

      & .complete-table {
        width: 35% !important;
      }
    }
  }
}

.panel {
  &.alerts-panel {
    padding: 0;

    & .title {
      display: none;
    }

    & .content {
      margin: 0;

      & .selector {
        width:100%;
      }

      & .paginator {
        width: 100%;
      }

      & .alert-row {
        padding: 0;

        & .row-content {
          padding: 11px;
          position:static;    
          height: 100%;
          box-sizing: border-box;

          & > div {
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
      }
    }
  }
}

.facility-workflow {
  background: linear-gradient(180deg, #6a7caf 0%, #242e42 40%);
  background-color: #242e42;
  padding: 30px 60px 30px 60px;

  & .header {
    height: 80px;
    display: flex;

    & .title {
      width: 50%;
      color: #ffffff;
      font-size: 36px;
      font-weight: 800;
      line-height: 55px;
    }

    & .steps {
      width: 50%;
      text-align: end;
      display: flex;

      & .circle {
        margin-left: 30px;
      }
    }
  }

  & .content {
    // width: 100%;
    width: auto;
    height: fit-content;
    background-color: #2f3b52;
    border-radius: 12px;
    padding: 20px 0 40px 0;

    & .title {
      padding-left: 40px;
      padding-right: 40px;
    }
  }

  & .footer {
    width: 100%;
    height: 70px;
    text-align: end;
    padding-top: 20px;
    display: flex;

    justify-content: flex-end;

    & .btn {
      margin-left: 40px;
    }
  }
}

.facility-profile-container {
  padding: 70px 40px;
  background: linear-gradient(180deg, #6a7caf 0%, #242e42 250px);

  & .facility-data {
    background-color: #2f3b52;
    padding: 20px 0;
    border-radius: 10px;
  }
}

.facility-limit-options {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  border: 1px solid rgba(255, 255, 255, 0);

  & .indicator {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    font-size: 12px;
    font-weight: bold;
    line-height: 17px;
    text-align: center;

    &.approved {
      background-color: #05c985;
    }

    &.pending {
      background-color: #e19027;
      line-height: 19px;
      padding-left: 3px;
      box-sizing: border-box;
    }

    &.rejected {
      background-color: #eb496b;
      line-height: 19px;
      padding-right: 1px;
      box-sizing: border-box;
    }
  }

  & .btn {
    height: 20px;
    width: 42%;
    font-size: 10px;
    font-weight: normal;

    &:focus {
      outline: none;
    }
  }
}

.facilities-date-selection {
  display: flex;
  background-color: #212a3c;

  & .facilities-calendar-ribbon {
    justify-content: flex-end !important;
  }

  & .react-datepicker-wrapper {
    width: 35%;
    display: flex;
    align-content: center;
    align-items: center;
  }
}

.facility-profile-edit {
  & .actions {
    display: flex;
    justify-content: flex-end;

    & > button {
      margin: 10px;
    }
  }
}

.facility-profile {
  & .top-row {
    display: flex;

    & .facility-tags-container {
      border-radius: 10px;
      display: flex;
      background-color: rgb(28, 37, 53);
      width: calc(70% - 40px);
      margin-left: 40px;
      margin-top: 60px;

      & .container-item {
        width: 33%;
        height: 100%;
      }

      & .double-size {
        width: 66% !important;
      }

      & .line-divider {
        background-color: rgb(93, 97, 106);
        width: 1px;
        height: calc(100% - 30px);
        margin-top: 15px;
      }
    }

    & .facility-info {
      min-width: 365px;

      width: 30%;
      border-radius: 12px;
      background-color: #20293d;
      position: relative;
      padding: 10px 25px;
      padding-bottom: 0;
      box-sizing: border-box;

      & .profile-information {
        & .edit-icon {
          position: absolute;
          top: 75px;
          right: 20px;
          color: #afc8ff;
          cursor: pointer;
        }
      }

      & .avatar-container {
        height: 90px;
        width: 90px;
        border-radius: 100px;
        position: absolute;
        left: 50%;
        top: 0;
        transform: translate(-50%, -50%);
        background: #fff;

        & .avatar {
          height: 50px;
          position: absolute;
          top: 47%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }

      & .avatar {
        height: 80px;
        // border-radius: 50px;
        position: absolute;
        top: 0;
        left: 50%;
        transform: translate(-50%, -50%);
      }

      & .code {
        color: #ffb643;
        font-size: 14px;
        font-weight: bold;
      }

      & .name {
        text-align: center;
        font-size: 20px;
        font-weight: bold;
        color: #ffffff;
        margin-bottom: 0;
      }

      & .alias {
        text-align: center;
        color: #afc8ff;
        font-size: 14px;
        line-height: 22px;
        margin-top: 0;
      }

      & .edit-icon {
        position: absolute;
        top: 20px;
        right: 20px;
        color: #afc8ff;
        cursor: pointer;
      }

      & .darker {
        background-color: #1a202e;
        margin-left: -25px;
        margin-right: -25px;
        display: flex;
        max-height: 40%;

        & > div {
          padding: 15px;
          box-sizing: border-box;
        }

        & .facility-location {
          width: 55%;
          color: rgba(255, 255, 255, 0.8);
          font-size: 14px;
          line-height: 23px;
          border-right: 1px solid rgba(255, 255, 255, 0.2);
          padding-left: 40px;
          box-sizing: border-box;
          position: relative;
          overflow-y: auto;

          & .location-pin {
            position: absolute;
            left: 15px;
            top: 20px;
          }
        }

        & .facility-tags {
          width: 50%;
          color: #afc8ff;
          font-size: 16px;
          font-weight: bold;

          // & .tag .tag-body {
          //   padding: 4px;
          // }

          & span {
            color: #fff;
            background-color: #6988cd;
            border-radius: 4px;
            font-size: 12px;
            padding: 5px;
            margin: 3px;
            white-space: nowrap;
            display: inline-block;
          }
        }
      }

      & .facility-website {
        color: #afc8ff;
        font-size: 14px;
        font-weight: bold;
        line-height: 49px;
      }

      & .facility-users {
        position: absolute;
        right: 15px;
        bottom: 26px;

        & img {
          margin: 0 3px 0 13px;
          position: relative;
          top: 5px;
        }
      }
    }

    & .facility-orbi-levels {
      width: 70%;
      display: flex;
      margin-left: 30px;
      border-radius: 12px;

      & .map {
        width: 100%;

        & .map-view {
          height: 100%;

          & div > img {
            border-radius: 25px;
          }
        }

        & .map-view-facility {
          height: 100%;
        }
      }

      & .facility-level {
        background-color: #1a202e;
        width: 50%;
        padding: 40px 20px 40px 20px;
        border-radius: 12px 0 0 12px;

        & .facility-level-info {
          position: relative;
          padding-left: 50%;
          height: 130px;

          & > div {
            text-align: center;
            color: #fff;
            letter-spacing: 3px;

            & p:first-child {
              font-size: 18px;
              font-weight: bold;
              line-height: 28px;

              & .current-level {
                color: #3ca1ff;
              }
            }

            & p:last-child {
              font-size: 32px;
              font-weight: bold;

              & img.coin {
                height: 32px;
                position: static;
                transform: none;
              }
            }
          }

          & img {
            position: absolute;
            height: 130px;
            left: 25%;
            transform: translateX(-50%);
          }
        }

        & .progress-bar {
          height: 10px;
          width: 100%;
          display: flex;
          margin: 10px 0;
          position: relative;

          & .complete {
            width: 85%;
            background-color: #3ca1ff;
          }

          & .missing {
            width: 15%;
            background-color: #37435d;
          }

          & .checkpoint {
            height: 4px;
            width: 4px;
            position: absolute;
            top: 3px;
            background-color: #fff;
            border-radius: 2px;

            &:first-child {
              left: 19%;
            }

            &:nth-child(2) {
              left: 39%;
            }

            &:nth-child(3) {
              left: 59%;
            }

            &:nth-child(4) {
              left: 79%;
            }
          }
        }

        & > p {
          text-align: center;
          color: #fff;
          font-size: 12px;
          margin-top: 20px;

          & .next-level {
            color: #d9a633;
          }
        }
      }

      & .facility-level-stats {
        width: 50%;
        border-radius: 0 12px 12px 0;
        background-color: #20293d;
        color: rgba(255, 255, 255, 0.6);
        padding: 15px 30px;

        & .selector {
          height: 40px;
          padding-top: 15px;
          text-align: center;

          & span {
            letter-spacing: 3px;
            margin: 0 15px;
            cursor: pointer;

            &.selected {
              color: #fff;
              position: relative;

              &:after {
                content: " ";
                height: 4px;
                width: 115px;
                position: absolute;
                bottom: -5px;
                left: 50%;
                transform: translateX(-50%);
                background: linear-gradient(
                  139.24deg,
                  #2f4ff1 0%,
                  #4184f9 100%
                );
              }
            }
          }
        }

        & .stats {
          & .row {
            display: flex;
            margin-bottom: 20px;

            & .stat-name {
              width: 40%;
              font-size: 12px;
            }

            & .progress-bar {
              width: 40%;
              height: 16px;
              display: flex;
              position: relative;

              & .percentage {
                position: absolute;
                left: 3px;
                top: 1px;
                font-size: 14px;
                font-weight: bold;
              }

              & .complete {
                width: 70%;
                background-color: #3d77f7;
              }

              & .missing {
                width: 30%;
                background-color: #1a202e;
              }

              &:after {
                content: " ";
                background-color: #fff845;
                height: 16px;
                width: 2px;
                position: absolute;
                left: 60%;
                top: 0;
              }
            }

            & .points {
              width: 20%;
              font-size: 16px;
              font-weight: bold;
              text-align: right;
              color: #fff;
            }
          }
        }

        & .orbi-points {
          border-top: 1px solid #d8d8d8;
          text-align: right;
          color: #fff;
          font-size: 16px;
          font-weight: bold;

          & img {
            height: 20px;
            margin-left: 24px;
            margin-right: 12px;
            position: relative;
            top: 4px;
          }
        }
      }
    }
  }

  & .bottom-row {
    margin-top: 40px;

    & .tabs {
      background-color: #1a202e;
      height: 70px;
      border-radius: 12px 12px 0 0;
      color: rgba(255, 255, 255, 0.4);
      font-size: 16px;
      font-weight: bold;
      letter-spacing: 1px;
      display: flex;

      & > div {
        line-height: 75px;
        text-align: center;
        margin-left: auto;
        margin-right: auto;
        position: relative;

        & span {
          cursor: pointer;
        }

        &.selected {
          color: #fff;

          &:after {
            content: " ";
            height: 6px;
            width: 80%;
            position: absolute;
            bottom: 0px;
            left: 50%;
            transform: translateX(-50%);
            background: linear-gradient(139.24deg, #2f4ff1 0%, #4184f9 100%);
          }
        }
      }
    }

    & .tab-content {
      min-height: 100px;
      background-color: #20293d;
      border-radius: 0 0 12px 12px;
      padding: 0 10px;
      min-height: 500px;
    }
  }
}

.permissions-table {
  & .table-header {
    display: flex;
    color: #a4b4ce;
    font-size: 16px;
    font-weight: bold;
    height: 70px;
    line-height: 70px;
    border-bottom: 2px solid;

    & > div {
      width: 17.5%;
      text-align: center;

      &:first-child {
        width: 30%;
        text-align: left;
      }
    }
  }

  & .table-row {
    height: 70px;
    line-height: 70px;
    display: flex;
    color: #afc8ff;
    font-size: 16px;
    border-bottom: 1px solid rgba(216, 216, 216, 0.2);

    & .permission-title {
      width: 30%;
      text-align: left;
      font-size: 14px;
      color: #fff;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    & .permission-control-container {
      width: 17.5%;
      text-align: center;

      & img {
        cursor: pointer;
        margin-left: 4px;
      }

      & .switch-container {
        position: relative;
        top: 50%;
        transform: translateY(-50%);
      }
    }

    // &>div {
    //   width: 17.5%;
    //   text-align: center;

    //   &:first-child {
    //     width: 30%;
    //     text-align: left;
    //     font-size: 14px;
    //     color: #fff;
    //   }

    //   & img {
    //     cursor: pointer;
    //     margin-left: 4px;
    //   }
    // }
  }

  & .table-actions {
    height: 75px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
}

.facility-data {
  display: flex;
  flex-wrap: wrap;

  & .data-title {
    color: #ffffff;
    font-size: 24px;
    line-height: 37px;
    width: 100%;
    padding-left: 40px;
    padding-right: 40px;
  }

  & .data-panel {
    display: flex;
    width: 100%;
    padding-left: 26px;
    padding-right: 50px;

    & .basic-data {
      width: 50%;
      // padding: 0 10px 0 40px;
      display: flex;
      flex-wrap: wrap;

      & .full {
        width: 90%;
      }

      & .half {
        width: 44%;
      }
    }

    & .extra-data {
      width: 50%;

      & .avatar {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 10px;

        & .frame {
          box-sizing: border-box;
          height: 184px;
          width: 184px;
          border: 4px dashed #ffffff;
          opacity: 0.3;
          border-radius: 12px;
          margin: 10px;
          text-align: center;

          &.selected {
            border: none;
            opacity: 1;
            // background-color: #3d77f7;
            background-color: #fff;
            text-align: center;
          }
        }

        & button {
          width: 218px;
        }
      }

      & .map {
        border-radius: 12px;
        padding: -12px;

        & .map-view {
          border-radius: 12px;
          width: auto;
          height: 400px;
        }
      }
    }
  }

  & .tags-panel {
    width: 100%;
    padding: 20px 10px 0 30px;

    & .text {
      color: #ffffff;
      font-size: 16px;
      line-height: 25px;
      padding-bottom: 20px;
      padding-left: 2px;
    }
  }
}

.facility-users {
  // margin-top: -20px;
  // margin-right: -80px;
  // margin-left: -50px;
  // margin-bottom: -40px;
  color: #fff;

  & .content-header {
    line-height: 92px;
    padding-left: 40px;
    padding-right: 40px;
    // padding: 0px 0px 50px 20px;
    font-size: 24px;
    font-weight: bold;
    color: #fff;
    position: relative;

    .search-bar {
      width: 370px;
      position: absolute;
      right: 250px;
      top: 0px;
    }

    button {
      position: absolute;
      right: 50px;
      top: 28px;

      & .user-icon {
        // margin-right: 5px;
        transform: translate(-50%, -1px);
      }
    }
  }

  & .userList-header {
    line-height: 80px;
    display: flex;
    color: #a4b4ce;
    font-weight: bold;
    background: #3d5074;
    border-bottom: 2px solid #7081a0;
  }

  & .user-row {
    display: flex;
    line-height: 80px;
    color: #fff;
    border-bottom: 1px solid #a4b4ce;
    background-color: #3d5074;
    position: relative;

    & .user-avatar {
      width: 56px;
      height: 56px;
      margin: auto;
      display: flex;
      position: relative;
      align-self: center;

      & .user-image {
        width: 100%;
        height: 100%;
        // margin-top: 12px;
        // margin-bottom: 12px;
        // position: absolute;
        border-radius: 45px;
        background-color: white;
        display: flex;
        justify-content: center;
        overflow: hidden;

        img {
          height: 100%;
          width: 100%;
          margin: auto;

          // right: 50%;
          // top: 50%;
          // transform: translateY(-50%);
        }

        &.default img {
          height: 60%;
          width: 60%;
        }
      }

      & .status {
        height: 10px;
        width: 10px;
        border-radius: 10px;
        position: absolute;
        right: 3;
        bottom: 0;
        background-color: #a4b4ce;
        border: 2px solid #2f3b52;

        &.connected {
          background-color: #6de155;
        }

        &.away {
          background-color: #ffc623;
        }

        &.disconnected {
          background-color: #a4b4ce;
        }
      }
    }

    & .user-name {
      width: 33%;
    }

    & .user-mail {
      width: 30%;
      font-size: 12px;
    }

    & .user-status {
      width: 20%;
      font-size: 12px;
    }

    & .user-actions {
      width: 10%;
      position: relative;

      img {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        cursor: pointer;
      }
    }
  }

  & .user-row:hover {
    background-color: #526a99;
    box-shadow: 0px 0px 20px 11px #20293d;
    position: relative;
    border-bottom: 0px;
  }
}

.facility-geofences {
  & .title {
    color: #ffffff;
    font-size: 24px;
    // font-weight: bold;
    line-height: 37px;

    &.dark {
      color: #20293d;
    }
  }

  & .content {
    display: flex;
    height: fit-content;

    & .map {
      width: 100%;
      position: relative;

      & .gm-style .gm-style-iw-t::after {
        display: none;
      }

      & .gm-ui-hover-effect {
        display: none !important;
      }

      & .add-geofence {
        z-index: 1;
        position: absolute;
        top: 10px;
        left: 10px;
        padding-top: 6px;
      }

      .tooltip {
        visibility: hidden;
        display: none;
        position: absolute;
        z-index: 1;

        border-radius: 12px;
        background-color: #ffffff;
        box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.24);

        &.open {
          visibility: visible;
          display: block;

          & .title {
            color: #20293d;
            font-size: 24px;
            font-weight: 800;
            line-height: 37px;
          }
        }
      }

      & .add-geofence-tooltip {
        top: 20px;
        left: 20px;
        width: 458.8px;

        & .close-button {
          top: -10px;
          left: -10px;
          position: relative;
        }

        & .title {
          margin-left: 34px;
          top: -30px;
          position: relative;
        }

        & .content {
          margin: 0 22px 16px 34px;
          border-radius: 12px;
          background-color: #ffffff;
          box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.24);
          padding: 0;
        }

        & .actions {
          text-align: right;
          padding: 0 16px 0 16px;
          margin: 16px;
        }
      }

      & .selected-geofence-type {
        width: 400px;
        position: absolute;
        z-index: 1;
        top: 14px;
        left: 70px;
        background: linear-gradient(210.14deg, #ffffff 0%, #f1f9ff 100%);
        border-radius: 6px;

        & .geofence-type-selector {
          cursor: default;
        }
      }

      & .geofence-type-selector {
        width: 100%;
        display: flex;
        cursor: pointer;

        & div {
          flex: 1;
          border-radius: 0;
          color: #657795;
          font-size: 16px;
          font-weight: bold;
          line-height: 39px;
          text-align: center;
          border-right: 1px #96a0aa solid;

          &:first-child {
            border-radius: 6px 0 0 6px;
            border-right: 1px #96a0aa solid;
          }

          &:last-child {
            border-radius: 0 6px 6px 0;
            border-right: none;
          }

          &.selected {
            color: white;

            &.proximity {
              background-color: #55b740;
            }

            &.gate {
              background-color: #5eb5ff;
            }

            &.validation {
              background-color: #fff845;
              color: #657795;
            }

            &.ramp {
              background-color: #e9643f;
            }
          }

          height: 39px;
        }
      }

      & .map-view {
        border-radius: 12px;
        width: auto;
        height: 530px;
      }

      & .info-window-item {
        width: 285px;
        height: 170px;
        overflow: hidden;

        &.ramp {
          width: 250px;
          height: 230px;

          & .actions {
            display: inline-flex;

            & .type {
              width: 230px;
              margin: -35px 5px 0 5px;
            }
          }

          & .message {
            margin: 10px;
            color: #20293d;
            font-size: 18px;
            font-weight: 800;
            line-height: 28px;
          }

          & .table-top {
            display: flex;
            justify-content: flex-end;
            margin: 10px 15px 10px 0;
            text-align: center;

            & .table-header {
              color: #454e76;
              font-size: 14px;
              font-weight: 800;
              line-height: 17px;
              width: 70px;
              max-width: 70px;

              &.delete {
                width: 17px;
                max-width: 17px;
              }
            }
          }

          & .table-content {
            overflow-y: scroll;
            max-height: 120px;
            margin: 0 0 10px 0;
            text-align: center;

            & .equipment-selector {
              position: absolute;
              height: 229px;
              width: 175px;
              border-radius: 12px;
              background-color: #ffffff;
              box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.24);
              left: 10px;
              bottom: 10px;
              z-index: 1;

              & .equipment-selector-item-list {
                overflow-y: scroll;
                height: 80%;
                margin: 5px;
              }

              & .equipment-selector-item {
              }

              & .equipment-selector-action {
              }
            }

            & .table-row {
              display: flex;
              border-bottom: 1px solid #979797;
              justify-content: flex-end;
              margin: 10px 0 10px 0;
              padding: 5px 0 5px 0;
              align-items: center;

              & .equipment-list {
                width: 100%;
                max-width: 125px;
                height: 35px;
                max-height: 35px;
                display: flex;
                color: #20293d;
                font-size: 12px;
                font-weight: bold;
                line-height: 12px;
                text-align: left;
                align-items: flex-end;

                & span {
                  color: #3d77f7;
                }

                & .equipment-edit {
                  color: #3d77f7;
                  font-size: 18px;
                  -moz-transform: scale(-1, 1);
                  -webkit-transform: scale(-1, 1);
                  -o-transform: scale(-1, 1);
                  -ms-transform: scale(-1, 1);
                  transform: scale(-1, 1);
                  cursor: pointer;
                  text-decoration: underline;
                }
              }

              & .capacity-truck {
                width: 40px;
                max-width: 40px;
                display: flex;
                flex-direction: column;
                align-items: flex-end;

                & img {
                  height: 100%;
                  width: 20px;
                  padding: 3px;

                  &.double-capacity {
                    height: 13px;
                    width: 30px;
                  }
                }
              }

              & .load {
                width: 70px;
                max-width: 70px;
                display: flex;
                flex-direction: column;
                align-items: center;
              }

              & .unload {
                width: 70px;
                max-width: 70px;
                display: flex;
                flex-direction: column;
                align-items: center;
              }

              & .load-unload {
                width: 70px;
                max-width: 70px;
                display: flex;
                flex-direction: column;
                align-items: center;
              }

              & .capacity-type {
                width: 70px;
                max-width: 70px;
              }

              & .equipment-delete {
                max-width: 17;
                width: 17;
                cursor: pointer;
              }

              & .right {
                & input[type="text"] {
                  text-align: center;
                  width: 25px;
                  border: none;
                  border-bottom: 1px solid;
                }
              }
            }
          }

          & .buttons {
            display: flex;

            // justify-content: space-between;
            & .left {
              width: 50%;
            }

            & .right {
            }
          }
        }
      }
    }

    & .tooltip-content {
      padding: 10px;
    }

    & .title {
      text-align: left;
      padding: 0;
    }

    & .text-input {
      width: auto;
      margin-top: -10;
    }

    & .actions {
      margin-top: 25px;
      text-align: center;
      display: flex;

      & button {
        width: 100px;
        margin: 0 20px 0 0;
      }
    }
  }

  & .collapser {
    position: relative;
    width: 23px;
    height: 30px;
    top: 235px;
    left: -7px;
    border: #a7b6d6 solid 1px;
    background: no-repeat #2f3b52 center;
    background-image: url("/images/collapse-arrows.svg");
    transform: scale(1, 1);
    transition: transform 0.5s ease-in-out;
    cursor: pointer;
  }

  & .collapsed {
    .collapser {
      transform: scale(-1, -1);
      transition: transform 0.5s ease-in-out;
    }

    .controls {
      width: 5%;
      transition: 0.5s ease-in-out;

      & .geofence-item-control {
        display: block;

        & .dot {
          text-align: center;
          width: 20px;
          height: 20px;
          border-radius: 12px;
          color: #ffffff;
          font-size: 14px;
          line-height: 22px;
          margin: 20px 0 20px 0;

          & span {
            display: block;
          }
        }

        & .description,
        & .actions {
          display: none;
        }
      }
    }
  }

  & .controls {
    width: 40%;
    height: auto;
    transition: 0.5s ease-in-out;
    overflow-y: scroll;
    max-height: 530px;
    color: white;
    overflow-x: hidden;

    & .geofence-item-control {
      margin: 10px 0px;
      display: flex;
      align-items: center;

      &.last {
        border-bottom: solid 1px #d8d8d8;
        margin-bottom: 10px;
        padding-bottom: 10px;
      }

      & .dot {
        width: 14px;
        height: 12px;
        border-radius: 12px;
        color: #ffffff;
        margin-bottom: 3px;
      }

      & .description {
        color: #ffffff;
        font-size: 16px;
        // line-height: 25px;
        margin-left: 12px;
        width: 80%;
        overflow: hidden;
      }

      & .geo-actions {
        display: flex;
        align-items: center;

        & .delete {
          width: 20px;
          height: 20px;
          background: url("/images/delete.svg") no-repeat;
          cursor: pointer;
          margin: 0px 8px;
        }

        & .edit {
          width: 20px;
          height: 20px;
          background: url("/images/edit.svg") no-repeat;
          cursor: pointer;
          margin: 0px 8px;
        }
      }

      &.proximity .dot {
        background-color: #55b740;
      }

      &.gate .dot {
        background-color: #5eb5ff;
      }

      &.validation .dot {
        background-color: #fff845;
        color: black;
      }

      &.ramp .dot {
        background-color: #e9643f;
      }
    }
  }
}

.facility-config {
  color: #fff;

  & .title {
    height: 90px;
    font-size: 24px;
    font-weight: bold;
    line-height: 37px;
    position: relative;

    & .tabs {
      position: absolute;
      bottom: 0px;
      left: 40px;
      font-size: 16px;
      line-height: 25px;

      & span {
        border-radius: 8px 8px 0 0;
        background-color: #3d5074;
        opacity: 0.4;
        padding: 15px 20px 5px 20px;
        cursor: pointer;

        &.selected {
          opacity: 1;
          background-color: #212a3c;
        }

        & + span {
          margin-left: 15px;
        }
      }
    }
  }

  & .content {
    margin-bottom: -40px;
    background-color: #212a3c;
    border-radius: 0 0 12px 12px;
    padding: 20px 54px;

    & .intro {
      font-size: 12px;
      opacity: 0.8;
    }
  }
}

// .facility-schedules {
.facility-schedules {
  & .attention-span-grid-row {
    &.row-disabled {
      -webkit-filter: blur(1px);
      -moz-filter: blur(1px);
      -o-filter: blur(1px);
      -ms-filter: blur(1px);
      filter: blur(1px);
      opacity: 0.3;
      // width: 100px;
      // height: 100px;
      // background-color: #ccc;
    }
  }

  & .default-time-selection {
    display: flex;
    align-items: center;
    color: #fff;
    font-size: 12px;

    & .default-time-selector {
      width: 50px;
      height: 40px;
      border-bottom: 2px solid #2399d2;
      margin: 0 4px 0 10px;

      & .simple-list {
        color: #000;
      }
    }
  }
}

.spaces-modal {
  & .message {
    margin-bottom: 20px !important;
  }

  & .space-tags {
    max-height: 150px;
    overflow-y: auto;

    & .title {
      display: none;
    }
  }

  & .schedule-container-content {
    margin-top: 15px;

    & .left-panel {
      width: 10%;
      float: left;
      overflow-x: hidden;
      height: 390px;
      cursor: grab;
      margin-top: 2px;

      & :last-child {
        border-width: 1px 0 1px 1px !important;
      }

      & :nth-child(odd) {
        border-width: 0 0 0 1px !important;
      }

      & :first-child {
        border-width: 1px 0 0 1px !important;
      }

      & .hours {
        height: 30px;
        width: 100%;
        color: white;
        border-width: 1px 0 1px 1px;
        border-style: solid;
        border-color: #2f3b52;
        line-height: 20px;
        text-align: center;
      }
    }

    & .right-panel {
      width: 90%;
      float: right;
      overflow: hidden;
      display: flex;
      height: 390px;

      & .schedule-container-column {
        width: 100%;
        min-width: 134px;
        flex: 1;
        position: relative;

        &:last-child
          > .schedule-container-data-container
          > .schedule-container-data {
          border-right-width: 1px !important;
        }

        & .schedule-container-data-container {
          & .schedule-container-data {
            height: 30px;
            border-width: 1px 0 1px 1px;
            border-style: solid;
            border-color: #46526a;
          }

          & :first-child {
            border-width: 1px 0 0 1px !important;
          }

          & :last-child {
            border-width: 1px 0 1px 1px !important;
          }

          & :nth-child(odd) {
            border-width: 0 0 0 1px;
            // border-width: 0 0 0 2px;
            // border-style: solid;
            // border-color: gray;
          }

          & :nth-child(even) {
            border-top-color: rgba(70, 82, 106, 0.3);
            // border-width: 0 0 0 2px;
            // border-style: solid;
            // border-color: gray;
          }
        }

        & .schedule-container-item {
          position: absolute;
          left: 0;
          right: 0;
          //height: 61px; //32.5px one element
          background-color: rgba(0, 110, 255, 0.836);
          border-radius: 5px;
          -moz-user-select: none;
          -khtml-user-select: none;
          -webkit-user-select: none;
          user-select: none;
          cursor: pointer;

          & span {
            position: absolute;
            display: inline-block;
            width: 100%;
            color: white;
            text-align: center;
            font-size: 15px;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          }

          & span.close {
            top: 10px;
            text-align: right;

            & a {
              margin-right: 5px;
              color: lightgray;
              text-decoration: none;
            }
          }
        }
      }
    }
  }
}

.resource-control-header {
  & .resource-id {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 140px;
  }
}

.alerts-view {
  & .alerts-header {

  }

  & .alerts-content {
    & .dashboard-stats-shared {
      display: flex;
      justify-content: space-between;

      &.half > div {
        width: 49%;
      }
    }
    & .dashboard-panel {
      background-color: #2F3B52;
      border-radius: 12px;
      margin-top: 20px;
      position: relative;

      &.stats-panel {
        & .total {
          text-align: center;
          display: block;
          color: #ffffff;
          font-size: 86px;
          line-height: 70px;
          padding: 20px;
          margin: 0;
          padding-bottom: 40px;
          box-sizing: border-box;
          min-height: 130px;
        }

        & .progress-bar {
          position: relative;
          margin-bottom: 70px;
          /* border: 4px solid #353A50; */
          border-radius: 8px;

          & .bar {
            display: flex;
            height: 8px;
            border-radius: 14px;
            /* border: 1px solid #717483; */
            overflow: hidden;
            /* box-shadow: 0 0 5px #000; */

            & div {
              height: 100%;
            }

            & .baja {
              background-color: #FFE358;
              box-sizing: border-box;
              border-right: 2px solid #262840;
            }
            & .media {
              background-color: #FF9145;
              border-left: 1px solid #262840;
              box-sizing: border-box;
              border-right: 1px solid #262840;
            }
            & .alta {
              background-color: #FE2F56;
              border-left: 2px solid #262840;
              box-sizing: border-box;
            }
          }

          & .mark {
            position: absolute;
            top: 22px;
            background-color: #868DAA;
            width: 1px;
            height: 42px;
            display: block;
            margin-left: -1px;
          }

          & .label {
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            top: 22px;
            text-align: center;
            color: #fff;
            line-height: 21px;
            font-size: 28px;
            font-weight: bold;

            & .pct {
              font-size: 18px;
              color: #fff;
              /* opacity: 0.5; */
              padding-top: 4px;
              font-weight: normal;
            }
          }
        }
      }

      // &.dashboard-stats {
      //   min-height: 352px;

      //   & .panel-content {
      //     padding-bottom: 0;
      //     overflow: visible;
      //     padding-top: 85px;

      //     & canvas {
      //       position: absolute;
      //       top: 79px;
      //       left: 70px;
      //     }
      //   }
      // }

      &.dashboard-alerts-month {
        & .panel-content {
          padding-top: 70px;
          & ul {
            list-style: none;
            margin: -20px auto 20px auto;
            padding: 0;
            display: block;
            text-align: center;
            z-index: 100;

            & li {
              list-style: none;
              margin: 0;
              padding: 0;
              display: inline-block;
              margin: 0 10px;
              font-size: 14px;
              text-transform: uppercase;
              color: #fff;
              line-height: 20px;
              cursor: pointer;

              &.selected {
                font-size: 18px;
                border-bottom: 2px solid #5BC8FF;
              }

              &.disabled {
                opacity: .5;
              }
            }
          }
        }
      }

      & .panel-title {
        display: block;
        color: #fff;
        font-size: 33px;
        line-height: 80px;
        margin: 0;
        /* font-weight: bold; */
        padding-left: 25px;
        min-height: 80px;
        position: absolute;
        min-width: 350px;

        & span {
          position: absolute;
          left: 25px;
          bottom: -25px;
          font-size: 15px;
        }
      }

      & .panel-content {
        flex: 1;
        overflow: auto;
        padding: 24px;
        padding-top: 85px;
        position: relative;

        &.arbol {
          padding-top: 150px;
        }

        // & .total {
        //   position: absolute;
        //   top: 214px;
        //   left: 203px;
        //   color: #fff;
        //   text-align: center;
        //   transform: translate(-50%, -50%);
        //   font-size: 27px;

        //   & b {
        //     display: block;
        //     font-size: 72px;
        //   }
        // }

        // & .stats {
        //   padding-left: 340px;
        //   padding-right: 100px;
        //   margin-top: 0px;

        //   & .icons {
        //     display: flex;
        //     flex-direction: row;
        //   }

        //   & .label {
        //     width: 150px;
        //     color: #9CA2AD;
        //     font-size: 19px;
        //     text-align: right;
        //     /* padding-right: 10px; */
        //     box-sizing: border-box;
        //     line-height: 30px;
        //     /* padding-top: 20px; */
        //     font-weight: normal;
        //   }

        //   & .icon {
        //     height: 40px;
        //     position: relative;
        //     display: inline-block;
        //     flex: 0.3333;

        //     &.transitoIda {
        //       margin-top: 10px;

        //       & svg {
        //         fill: #FE6901;
        //         color: #FE6901;
        //       }
        //     }

        //     &.transitoVuelta {
        //       margin-top: 10px;
        //       transform: scaleX(-1);
        //       & svg {
        //         fill: #F3C91D;
        //           color: #F3C91D;
        //       }
        //     }

        //     & svg {
        //       position: absolute;
        //       top: 50%;
        //       left: 50%;
        //       height: 55px;
        //       transform: translate(-50%, -50%);
        //       width: 55px;
        //     }
        //   }

        //   & .real {
        //     flex: 0.3333;
        //   }

        //   & .data {
        //     font-size: 20px;
        //     line-height: 40px;
        //     color: #fff;
        //     padding-top: 25px;
        //     display: flex;
        //     flex-direction: row;
  
        //     &.real {
        //       border-bottom: 1px solid #fff;
        //     }

        //     & .real {
        //       color: #fff;
        //       text-align: center;
        //       font-size: 60px;
        //       line-height: 70px;
        //     }

        //     & .deviation {
        //       color: #3FE14F;
        //       line-height: 63px;
        //       font-size: 54px;
        //       flex: 0.3333;
        //       text-align: center;

        //       &.alert {
        //         color: #FE2F56;
        //         background-color: transparent;
        //       }
        //     }
        //   }
        // }


        & .loader {
          display: block;
          margin: 0 auto;
          padding: 20px;
          width: 48px;
          padding-top: 85px;
          & svg {
            width: 48px;
            height: 48px;
          }
        }

        & .pareto-table {
          & .table-header {
            line-height: 30px;
            color: #fff;
            font-size: 15px;
            /* padding-left: 5px; */
            color: #ffffff;
            position: relative;
            display: flex;
            flex-direction: row;
            /* font-weight: bold; */
            /* margin-right: 16px; */
            text-align: center;
            box-sizing: border-box;

            & div {
              border: solid 1px white;
              display: flex;
              align-items: center;
              justify-content: center;
            }
          }

          & .Ruta {
            min-width: 170px;
            flex: 1;
          }
          & .Signatura {
              width: 115px;
          }
          & .F-Ida {
              width: 115px;
          }
          & .F-Vuelta {
              width: 115px;
          }
          & .Linea {
              width: 150px;
          }
          & .Camion {
              width: 125px;
          }& .Inicio {
              width: 113px;
          }
          & .Termino {
              width: 113px;
          }
          & .Meta {
              width: 90px;
          }
          & .Real {
              width: 80px;
          }
          & .Desv {
              width: 80px;
          }
          
          & .trip .Ruta {
              min-width: 170px;
          }
          & .trip .Signatura {
              width: 117px;
          }
          & .trip .F-Ida {
              width: 117px;
          }
          & .trip .F-Vuelta {
              width: 117px;
          }
          & .trip .Linea {
              width: 152px;
          }
          & .trip .Camion {
              width: 127px;
          }
          & .trip .Inicio {
              width: 115px;
          }
          & .trip .Termino {
              width: 115px;
          }
          & .trip .Meta {
              width: 92px;
          }
          & .trip .Real {
              width: 82px;
          }
          & .trip .Desv {
              width: 81px;
              font-weight: bold;
          }
          
          
          & .Signatura-small {
              width: 93px;
          }
          & .F-Ida-small {
              width: 93px;
          }
          & .F-Vuelta-small {
              width: 93px;
          }
          & .Linea-small {
              width: 113px;
          }
          & .Camion-small {
              width: 98px;
          }
          & .Inicio-small {
              width: 113px;
          }
          & .Termino-small {
              width: 113px;
          }
          & .Cita-small {
              width: 160px;
          }
          & .Puntualidad-small {
              width: 53px;
          }
          & .Meta-small {
              width: 83px;
          }
          & .Real-small {
              width: 83px;
          }
          & .Desv-small {
              width: 80px;
          }
          
          & .trip .Signatura-small {
              width: 95px;
          }
          & .trip .F-Ida-small {
              width: 95px;
          }
          & .trip .F-Vuelta-small {
              width: 95px;
          }
          & .trip .Linea-small {
              width: 115px;
          }
          & .trip .Camion-small {
              width: 100px;
          }
          & .trip .Inicio-small {
              width: 115px;
          }
          & .trip .Termino-small {
              width: 115px;
          }
          & .trip .Cita-small {
              width: 162px;
          }
          & .trip .Puntualidad-small {
              width: 55px;
          }
          & .trip .Meta-small {
              width: 85px;
          }
          & .trip .Real-small {
              width: 85px;
          }
          & .trip .Desv-small {
              width: 81px;
              font-weight: bold;
          }

          & .list {
            max-height: calc(100vh - 360px);
            overflow: auto;
          }
          & .trip {
              line-height: 40px;
              color: #fff;
              font-size: 15px;
              padding-left: 0px;
              position: relative;
              display: flex;
              flex-direction: row;
              text-align: center;
              background-color: #a3a3af;
              margin-top: 5px;
              border-radius: 5px;
              /* border-left: 4px solid #008CFF; */
          }
          & .trip > div:first-child {
              text-align: left;
              padding-left: 10px;
              box-sizing: border-box;
          }
          & .trip > div {
              overflow-x: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
          }
        }
      }
      & .filtros {
        position: absolute;
        right: 5%;
        padding-top: 50px;
        padding-left: 0px;
        /* display: block; */
        color: #fff;
        list-style: none;
        z-index: 1;
        display: inline-flex;

        & .filter-content {
          background-color: #242e4200;
        }

        & .desviacion {
          margin-right: 20px;

      //     &.etapas {
      //       position: absolute;
      //       top: 49px;
      //       right: 2px;
      //       margin: 0px;
      //       display: inline-flex;

      //       & li {
      //         padding: 7px;
      //       }
      //     }

      //     &.zoom {
      //       display: inline-flex;
      //     }
        }

      //   & .zoom {
      //     & .navigate {
      //       position: relative;
      //       left: -4%;
      //       font-size: 20px;
      //       font-weight: bolder;
      //       line-height: 30px;
      //       background-color: #b5b5b5;
      //       line-height: 30px;
      //       padding-left: 14px;
      //       border-radius: 0px 20px 20px 0px;
      //       color: #2cb5b2;

      //       &.estadiaPlanta {
      //         color: #4472c4;
      //       }
      //       &.transitoIda {
      //         color: #ed7d31;
      //       }
      //       &.estadiaAgencia {
      //         color: #686464;
      //       }
      //       &.transitoVuelta {
      //         color: #e2ac00;
      //       }

      //       & span {
      //         &.prev {
      //           padding: 0px 10px;
      //           cursor: pointer;
      //         }

      //         &.pag {
      //           padding: 0px 5px;
      //         }
      //       }
      //     }
      //   }

        & li {
          display: inline-block;
          cursor: pointer;
          margin: 0px 10px;
          list-style: none;

          &.desviacion,
          &.pantalla {
            line-height: 30px;
            min-width: 65px;
            text-align: center;
            border: 1px solid white;
            margin: 0px;
            padding: 0px 15px;

            &.selected {
              background-color: #5bccff;
              font-weight: bold;

              // & div {
              //   height: 4px;
              //   width: 80%;
              //   background-color: #2cb5b2;
              //   border-radius: 34px;
              //   position: absolute;
              //   left: 10%;
              //   transform: translateY(-110%);
              // }

              
            }

            &:first-child {
              border-radius: 5px 0px 0px 5px;
            }
          }

          &.desviacion:nth-child(0n+2),
          &.pantalla:last-child{
            border-radius: 0px 5px 5px 0px;
          }
        }

      //   & .etapa-selected {
      //     border-radius: 20px;
      //     color: #fff !important;

      //     &.global {
      //       color: #fff;
      //       background-color: transparent;
      //     }

      //     &.planta {
      //       background-color: #4CABFF;
      //     }

      //     &.agencia {
      //       background-color: #ACACAC;
      //     }

      //     &.ida {
      //       background-color: #FE6901;
      //     }

      //     &.vuelta {
      //       background-color: #F3C91D;
      //     }
      //   }

      //   & .global-back {
      //     width: 24px;
      //     position: absolute;
      //     height: 24px;
      //     background-color: #4ce0c7;
      //     z-index: 1;
      //     left: -50px;
      //     top: 0px;
      //     border-radius: 25px;
      //   }

      //   & .not-selected {
      //     color: #fff !important;
      //   }

      //   & .planta {
      //     color: #4CABFF;
      //   }

      //   & .ida {
      //     color: #FE6901;
      //     transform: translateY(3px);
      //   }

      //   & .agencia {
      //     color: #ACACAC;
      //   }

      //   & .vuelta {
      //     color: #F3C91D;
      //   }
      }
      & .download-icon {
        position: absolute;
        bottom: 10px;
        right: 10px;
        cursor:pointer;
      }
    }
  }
}

.copy-icon-style {
  cursor: pointer;
  width: 16px;
  height: 16px;
  min-width: 16px;
  min-height: 16px;
  margin-left: 3px;
  
  &:hover {
    width: 20px;
    height: 20px;
    min-width: 20px;
    min-height: 20px;
  }
}

.availability-view {
  display: flex;
  flex-direction: column;

  & .top-bar {
    height: 70px;
    background-color: #242e42;
    display: flex;
    flex-direction: row-reverse;

    & .download-container {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
    }

    & .settings-container {
      width: 60px;
      height: 70px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

  }
  
}

.orders-column-filter {
  position: absolute;
  border: 1px solid #a4b4ce;
  box-sizing: border-box;
  border-radius: 12px;
  width: 280px;
  max-height: 430px;
  flex-wrap: wrap;
  background-color: white;
  z-index: 99999;
  padding: 20px;
  overflow: hidden;

  &.date {
    padding: 0;
    width: 244px;

    & .deselector {
      // top: 0;
      top: 44px;
      line-height: 30px;
    }

    & .items {
      overflow-y: hidden !important;
    }
  }

  & .separator {
    width: 100%;
    height: 0px;
    border: solid 0.5px #a4b4ce;
    margin-bottom: 5px;
  }

  & .deselector {
    z-index: 3;
    position: absolute;
    top: 8px;
    right: 15px;
    color: #5097d5;
    font-size: 12px;
    font-weight: bold;
    cursor: pointer;
  }

  & .search-list {
    width: 100%;

    & .text-input {
      width: 100%;
      margin: 0;
      top: -20px;
    }
  }

  & .checkbox-content {
    & .checkbox{
      display: flex;
      height: 25px;
      align-items: center;
      color: #20293d;
      font-size: 14px;
    }
  }

  & .checkmark {
    width: 20px;
    height: 20px;
  }

  & .items {
    width: 100%;
    // height: auto;
    display: flex;
    overflow-y: auto;
    overflow-x: hidden;
    max-height: 250px;

    &.chips {
      flex-wrap: wrap;
    }

    &.checks {
      flex-flow: column;
    }

    & .sub-item {
      margin-left: 15px;
    }
  }
}

.table-sortable {
  background-color: #2f3b52;
  // max-height: 500px;
  max-height: fit-content;
  border-radius: 10px;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  &.open {
    & .content {
      display: flex;
    }
  }

  & .content {
    position: absolute;
    padding: 0;
    border: 1px solid #a4b4ce;
    box-sizing: border-box;
    top: 70%;
    left: 70%;
    border-radius: 12px;
    width: 280px;
    max-height: 430px;
    flex-wrap: wrap;
    background-color: white;
    z-index: 9999;
    display: none;
    padding: 20px;
    overflow: hidden;

    &.date {
      padding: 0;
      width: 244px;

      & .deselector {
        // top: 0;
        top: 44px;
        line-height: 30px;
      }

      & .items {
        overflow-y: hidden !important;
      }
    }

    & .separator {
      width: 100%;
      height: 0px;
      border: solid 0.5px #a4b4ce;
      margin-bottom: 5px;
    }

    & .deselector {
      z-index: 3;
      position: absolute;
      top: 8px;
      right: 15px;
      color: #5097d5;
      font-size: 12px;
      font-weight: bold;
      cursor: pointer;
    }

    & .search-list {
      width: 100%;

      & .text-input {
        width: 100%;
        margin: 0;
        top: -20px;
      }
    }

    & .checkbox-content {
      & .checkbox{
        display: flex;
        height: 25px;
        align-items: center;
        color: #20293d;
        font-size: 14px;
      }
    }

    & .checkmark {
      width: 20px;
      height: 20px;
    }

    & .items {
      width: 100%;
      // height: auto;
      display: flex;
      overflow-y: auto;
      overflow-x: hidden;
      max-height: 250px;

      &.chips {
        flex-wrap: wrap;
      }

      &.checks {
        flex-flow: column;
      }

      & .sub-item {
        margin-left: 15px;
      }
    }
  }


  & .no-data {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 70px;
    color: white;
  }

  & .header {
    color: white;
    font-size: 13px;
    background-color: #394c75;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    height: 80px;
    min-height: 80px;
    display: flex;
    overflow: hidden;
    padding-right: 18px;

    & .space {
      width: 100%;
      min-width: 150px;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      margin-left: 20px;
      // position: relative;
    }

  }

  & .scroll {
    overflow: scroll;
    min-height: 1px;
  }

  & .rows-container {
    height: 450px;
    overflow-y: scroll;
    overflow-x: hidden;
    padding: 0px;
    flex-grow: 1;

    & .row {
      display: flex;
      flex-direction: column;
      overflow: hidden;
      
      & .row-content {
        color: white;
        font-size: 13px;
        // height: 80px;
        height: 50px;
        display: flex;

        & .space {
          width: 100%;
          min-width: 150px;
          display: flex;
          justify-content: center;
          align-items: center;
          text-align: center;
          margin-left: 20px;

          & .copy-icon {
            margin-left: 3px;
            cursor: pointer;
          }
        }

      }

      & .line {
        height: 1px;
        width: 9510px;
        background-color: white;
      }

    }
  }

}

.tat-view {
  & .tat-header {
    & .refresh-button {
      line-height: 40px;
      width: 40px;
      background-color: #fff;
      color: #008CFF;
      cursor: pointer;
      /* top: 0; */
      position: fixed;
      right: 25px;
      text-align: center;
      z-index: 11;
      height: 40px;
      border-radius: 40px;
      bottom: 50px;
      bottom: 15px;
      box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
      transition: all 0.3s;
      padding-left: 25px;
      box-sizing: border-box;
      overflow: hidden;

      &.active {
        background-color: #008CFF;
        color: #fff;
        width: 90px;
      }

      & svg {
        position: absolute;
        top: 50%;
        left: 20px;
        transform: translate(-50%, -50%);
        height: 16px;
      }
    }
  }
  & .tat-content {
    & .dashboard-panel {
      background-color: #2F3B52;
      border-radius: 12px;
      margin-top: 20px;
      position: relative;

      &.dashboard-stats {
        min-height: 352px;

        & .panel-content {
          padding-bottom: 0;
          overflow: visible;
          padding-top: 85px;

          & canvas {
            position: absolute;
            top: 79px;
            left: 70px;
          }
        }
      }

      &.dashboard-TAT-Month {
        & .panel-content {
          padding-top: 70px;
          & ul {
            list-style: none;
            margin: -20px auto 20px auto;
            padding: 0;
            display: block;
            text-align: center;
            z-index: 100;

            & li {
              list-style: none;
              margin: 0;
              padding: 0;
              display: inline-block;
              margin: 0 10px;
              font-size: 14px;
              text-transform: uppercase;
              color: #fff;
              line-height: 20px;
              cursor: pointer;

              &.selected {
                font-size: 18px;
                border-bottom: 2px solid #5BC8FF;
              }

              &.disabled {
                opacity: .5;
              }
            }
          }
        }
      }

      & .panel-title {
        display: block;
        color: #fff;
        font-size: 33px;
        line-height: 80px;
        margin: 0;
        /* font-weight: bold; */
        padding-left: 25px;
        min-height: 80px;
        position: absolute;
        min-width: 350px;

        & span {
          position: absolute;
          left: 25px;
          bottom: -25px;
          font-size: 15px;
        }
      }

      & .panel-content {
        flex: 1;
        overflow: auto;
        padding: 24px;
        padding-top: 0px;
        position: relative;

        &.rutas {
          padding-top: 150px;
        }

        & .charts {
          min-height: 400px;
        }

        & .total {
          position: absolute;
          top: 214px;
          left: 203px;
          color: #fff;
          text-align: center;
          transform: translate(-50%, -50%);
          font-size: 27px;

          & b {
            display: block;
            font-size: 72px;
          }
        }

        & .stats {
          padding-left: 340px;
          padding-right: 100px;
          margin-top: 0px;

          & .titles {
            display: flex;
            flex-direction: row;

            & > b {
              height: 40px;
              flex: 0.3333;
              font-size: 33px;
              text-align: center;
            }
            & .allocation {
              color: #bfbdb4;
            }

            & .adoption {
              color: #9bbdd9;
            }

            & .utilization {
              color: #4193cd;
            }

            & .quality {
              color: #41707c
            }
          }

          & .icons {
            display: flex;
            flex-direction: row;
          }

          & .label {
            width: 150px;
            color: #9CA2AD;
            font-size: 19px;
            text-align: right;
            /* padding-right: 10px; */
            box-sizing: border-box;
            line-height: 30px;
            /* padding-top: 20px; */
            font-weight: normal;
          }

          & .icon {
            height: 40px;
            position: relative;
            display: inline-block;
            flex: 0.3333;

            &.transitoIda {
              margin-top: 10px;

              & svg {
                fill: #FE6901;
                color: #FE6901;
              }
            }

            &.transitoVuelta {
              margin-top: 10px;
              transform: scaleX(-1);
              & svg {
                fill: #F3C91D;
                  color: #F3C91D;
              }
            }

            & svg {
              position: absolute;
              top: 50%;
              left: 50%;
              height: 55px;
              transform: translate(-50%, -50%);
              width: 55px;
            }
          }

          & .real {
            flex: 0.3333;
          }

          & .data {
            font-size: 20px;
            line-height: 40px;
            color: #fff;
            padding-top: 25px;
            display: flex;
            flex-direction: row;
  
            &.real {
              border-bottom: 1px solid #fff;
            }

            & .real {
              color: #fff;
              text-align: center;
              font-size: 60px;
              line-height: 70px;
            }

            & .deviation {
              color: #3FE14F;
              line-height: 63px;
              font-size: 54px;
              flex: 0.3333;
              text-align: center;

              &.alert {
                color: #FE2F56;
                background-color: transparent;
              }
            }
          }
        }


        & .loader {
          display: block;
          margin: 0 auto;
          padding: 20px;
          width: 48px;
          padding-top: 85px;
          & svg {
            width: 48px;
            height: 48px;
          }
        }

        & .pareto-table {
          & .table-header {
            line-height: 30px;
            color: #fff;
            font-size: 15px;
            /* padding-left: 5px; */
            color: #ffffff;
            position: relative;
            display: flex;
            flex-direction: row;
            /* font-weight: bold; */
            /* margin-right: 16px; */
            text-align: center;
            box-sizing: border-box;

            & div {
              border: solid 1px white;
            }
          }

          & .large-pct {
            width: 100%;
          }
          & .Ruta {
            min-width: 230px;
            flex: 1;
          }
          & .Signatura {
              width: 115px;
          }
          & .F-Ida {
              width: 115px;
          }
          & .F-Vuelta {
              width: 115px;
          }
          & .Linea {
              width: 150px;
          }
          & .Camion {
              width: 125px;
          }& .Inicio {
              width: 113px;
          }
          & .Termino {
              width: 113px;
          }
          & .Meta {
              width: 90px;
          }
          & .Real {
              width: 80px;
          }
          & .Desv {
              width: 80px;
          }
          
          & .trip .Ruta {
              min-width: 230px;
          }
          & .trip .Signatura {
              width: 117px;
          }
          & .trip .F-Ida {
              width: 117px;
          }
          & .trip .F-Vuelta {
              width: 117px;
          }
          & .trip .Linea {
              width: 152px;
          }
          & .trip .Camion {
              width: 127px;
          }
          & .trip .Inicio {
              width: 115px;
          }
          & .trip .Termino {
              width: 115px;
          }
          & .trip .Meta {
              width: 92px;
          }
          & .trip .Real {
              width: 82px;
          }
          & .trip .Desv {
              width: 81px;
              font-weight: bold;
          }
          
          
          & .Signatura-small {
              width: 93px;
          }
          & .F-Ida-small {
              width: 93px;
          }
          & .F-Vuelta-small {
              width: 93px;
          }
          & .Linea-small {
              width: 113px;
          }
          & .Camion-small {
              width: 98px;
          }
          & .Inicio-small {
              width: 113px;
          }
          & .Termino-small {
              width: 113px;
          }
          & .Cita-small {
              width: 113px;
          }
          & .Puntualidad-small {
              width: 53px;
          }
          & .Meta-small {
              width: 83px;
          }
          & .Real-small {
              width: 83px;
          }
          & .Desv-small {
              width: 80px;
          }
          
          & .trip .Signatura-small {
              width: 95px;
          }
          & .trip .F-Ida-small {
              width: 95px;
          }
          & .trip .F-Vuelta-small {
              width: 95px;
          }
          & .trip .Linea-small {
              width: 115px;
          }
          & .trip .Camion-small {
              width: 100px;
          }
          & .trip .Inicio-small {
              width: 115px;
          }
          & .trip .Termino-small {
              width: 115px;
          }
          & .trip .Cita-small {
              width: 115px;
          }
          & .trip .Puntualidad-small {
              width: 55px;
          }
          & .trip .Meta-small {
              width: 85px;
          }
          & .trip .Real-small {
              width: 85px;
          }
          & .trip .Desv-small {
              width: 81px;
              font-weight: bold;
          }

          & .list {
            max-height: calc(100vh - 360px);
            overflow: auto;
          }
          & .trip {
              line-height: 40px;
              color: #fff;
              font-size: 15px;
              padding-left: 0px;
              position: relative;
              display: flex;
              flex-direction: row;
              text-align: center;
              background-color: #a3a3af;
              margin-top: 5px;
              border-radius: 5px;
              /* border-left: 4px solid #008CFF; */
          }
          & .trip > div:first-child {
              text-align: left;
              padding-left: 10px;
              box-sizing: border-box;
          }
          & .trip > div {
              overflow-x: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
          }
        }
      }
      & .filtros {
        position: absolute;
        right: 5%;
        padding-top: 91px;
        padding-left: 0px;
        /* display: block; */
        color: #fff;
        list-style: none;
        z-index: 1;
        display: inline-flex;

        & .desviacion {
          margin-right: 20px;

          &.etapas {
            position: absolute;
            top: 49px;
            right: 2px;
            margin: 0px;
            display: inline-flex;

            & li {
              padding: 7px;
            }
          }

          &.zoom {
            display: inline-flex;
          }
        }

        & .zoom {
          & .navigate {
            position: relative;
            left: -4%;
            font-size: 20px;
            font-weight: bolder;
            line-height: 30px;
            background-color: #b5b5b5;
            line-height: 30px;
            padding-left: 14px;
            border-radius: 0px 20px 20px 0px;
            color: #2cb5b2;

            &.estadiaPlanta {
              color: #4472c4;
            }
            &.transitoIda {
              color: #ed7d31;
            }
            &.estadiaAgencia {
              color: #686464;
            }
            &.transitoVuelta {
              color: #e2ac00;
            }

            & span {
              &.prev {
                padding: 0px 10px;
                cursor: pointer;
              }

              &.pag {
                padding: 0px 5px;
              }
            }
          }
        }

        & li {
          display: inline-block;
          cursor: pointer;
          margin: 0px 10px;
          list-style: none;

          &.global {
            position: absolute;
            top: 1px;
            left: -49px;
            z-index: 2;
          }

          &.ida svg,
          &.vuelta svg {
            left: 1px;
            position: relative;
            top: 2px;
          }

          &.desviacion,
          &.pantalla {
            line-height: 30px;
            min-width: 95px;
            text-align: center;
            margin: 0px;
            margin-left: -5px;
            background-color: #d4d2d2;
            position: relative;
            font-weight: bold;
            color: #949494;

            &.quality {
              background-color: transparent;
            }

            &.selected {
              font-weight: bold;
              color: #2cb5b2;

              & div {
                height: 4px;
                width: 80%;
                background-color: #2cb5b2;
                border-radius: 34px;
                position: absolute;
                left: 10%;
                transform: translateY(-110%);
              }

              &.estadiaPlanta {
                color: #4472c4;
                & div {
                  background-color: #4472c4;
                }
              }
              &.transitoIda {
                color: #ed7d31;
                & div {
                  background-color: #ed7d31;
                }
              }
              &.estadiaAgencia {
                color: #686464;
                & div {
                  background-color: #686464;
                }
              }
              &.transitoVuelta {
                color: #e2ac00;
                & div {
                  background-color: #e2ac00;
                }
              }
            }

            &:first-child {
              border-radius: 15px 0px 0px 15px;
            }
          }

          &.desviacion:nth-child(0n+2),
          &.pantalla:last-child{
            border-radius: 0px 15px 15px 0px;
          }
        }

        & .etapa-selected {
          border-radius: 20px;
          color: #fff !important;

          &.global {
            color: #fff;
            background-color: transparent;
          }

          &.planta {
            background-color: #4CABFF;
          }

          &.agencia {
            background-color: #ACACAC;
          }

          &.ida {
            background-color: #FE6901;
          }

          &.vuelta {
            background-color: #F3C91D;
          }
        }

        & .global-back {
          width: 24px;
          position: absolute;
          height: 24px;
          background-color: #4ce0c7;
          z-index: 1;
          left: -50px;
          top: 0px;
          border-radius: 25px;
        }

        & .not-selected {
          color: #fff !important;
        }

        & .planta {
          color: #4CABFF;
        }

        & .ida {
          color: #FE6901;
          transform: translateY(3px);
        }

        & .agencia {
          color: #ACACAC;
        }

        & .vuelta {
          color: #F3C91D;
        }
      }
      & .download-icon {
        position: absolute;
        bottom: 10px;
        right: 10px;
        cursor:pointer;
      }
    }
  }
}

.landing-view {
  background-color: #fff;

  & .top-row {
    // background-image: url(./images/landing-long-background.png);
    // minHeight: "626px",
    min-height: 800px;
    background-size: 100%;
    background-repeat: no-repeat;
  }

  & .top-banner {
    min-height: 115px;
  }

  & .video-row {
    display: flex;

    & .video-container {
      width: 50%;
    }

    & .video-description {
      width: 50%;
      color: #ffffff;
      font-size: 20px;
      letter-spacing: 0;
      line-height: 30px;

      & .title {
        font-size: 48px;
        font-weight: 800;
        letter-spacing: -1.9px;
        line-height: 74px;
      }

      & .actions {
        margin-top: 20px;
      }
    }
  }

  & .company-type-row {
    padding: 130px 10% 207px 10%;

    & .intro {
      & .title {
        color: #2c2e30;
        font-size: 34px;
        letter-spacing: 0;
        line-height: 46px;
        text-align: center;
      }

      & .subtitle {
        color: #7e8085;
        font-size: 18px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 34px;
        text-align: center;
      }
    }

    & .company-types-container {
      margin-top: 60px;

      & .company-type-tabs {
        border-bottom: 2px solid #e7eaee;
        padding: 0 10%;
        & .tabs {
          display: flex;
          justify-content: space-between;

          & .tab {
            // margin: 0 20px;

            &:hover {
              cursor: pointer;
            }

            & img {
              height: 130px;
            }

            & .description {
              color: #2c2e30;
              font-size: 21px;
              letter-spacing: 0;
              line-height: 28px;
              text-align: center;
            }

            &.selected {
              font-weight: bold;
              border-bottom: 3px solid #2f5bea;
            }
          }
        }
      }

      & .carousel {
        width: 100%;
        height: 500px;
        z-index: 1;
        position: relative;
      }

      & .company-type-info {
        display: flex;

        & .avatar {
          width: 50%;
          position: relative;

          & img {
            width: 100%;
          }

          & .block {
            width: 100%;
            position: absolute;
            bottom: 0px;
            top: 0px;
            box-shadow: inset 0px 0px 20px 20px white;
          }
        }

        & .description {
          width: 50%;
          padding-top: 100px;

          & .title {
            color: #2c2e30;
            font-size: 28px;
            font-weight: bold;
            letter-spacing: 0;
            line-height: 38px;
          }

          & .subtitle {
            color: #54565a;
            font-size: 16px;
            font-weight: bold;
            letter-spacing: 0;
            line-height: 28px;
          }
        }
      }

      & .actions {
        display: flex;
        margin: 30px 0 0 50%;

        & .btn {
          margin: 0 15px;
        }
      }
    }
  }

  & .blue-banner {
    color: #fff;
    background: linear-gradient(133.1deg, #2d52cc 0%, #3270d2 100%);
  }

  & .second-banner {
    height: 361px;
    display: flex;
    box-sizing: border-box;
    padding: 122px 5% 82px 7%;

    & .main-text {
      width: 50%;
      border-right: 1px solid;
      color: #ffffff;
      font-size: 40px;
      font-weight: 600;
      letter-spacing: 0;
      line-height: 55px;
      text-align: justify;
      padding-right: 30px;
    }

    & .sub-text {
      width: 50%;
      padding-left: 30px;
      color: #ffffff;
      font-size: 18px;
      letter-spacing: 0;
      line-height: 28px;
    }
  }

  & .third-banner {
    padding: 30px 20px 30px 60%;

    & .controls {
      display: flex;
      align-items: center;
      margin-bottom: 30px;

      & .switch {
        height: 49px;
        width: 49px;
        background-color: #ffffff;
        box-shadow: 0 2px 34px 0 rgba(0, 0, 0, 0.21);
        border-radius: 50%;
        margin-right: 15px;
        position: relative;

        & img {
          position: absolute;
          top: 50%;
          left: 50%;
          // transform: translate(-50%, -50%);
          height: 17px;
        }

        &:hover {
          cursor: pointer;
        }

        &.left {
          & img {
            transform: translate(-57%, -50%) rotate(90deg);
          }
        }

        &.right {
          & img {
            transform: translate(-45%, -50%) rotate(-90deg);
          }
        }

        &.disabled {
          opacity: 0.2;
        }
      }

      & .counter {
        color: #ffffff;
        font-size: 25px;
        font-weight: 600;
        letter-spacing: 0;
      }
    }

    & .content {
      & .title {
        color: #ffffff;
        font-size: 40px;
        font-weight: 600;
        letter-spacing: 0;
        line-height: 55px;
      }

      & .text {
        color: #ffffff;
        font-size: 18px;
        letter-spacing: 0;
        line-height: 28px;
      }
    }

    & .actions {
      & .btn {
        width: 215px;
      }
    }
  }

  & .packages-row {
    display: flex;
    justify-content: center;
    margin-top: 90px;

    & .package {
      height: 474px;
      width: 314px;
      border-radius: 10px;
      background-color: #ffffff;
      box-shadow: 0 8px 8px 0 rgba(0, 0, 0, 0.05);
      position: relative;
      padding: 0 20px;
      box-sizing: border-box;

      &.main {
        z-index: 2;
        height: 547px;
        box-shadow: 0 32px 32px 0 rgba(0, 0, 0, 0.1);
      }

      & .name {
        color: #3b4750;
        font-size: 22px;
        font-weight: 600;
        letter-spacing: 0;
        line-height: 30px;
        text-align: center;
      }

      & .price {
        color: #57a4ff;
        font-size: 70px;
        letter-spacing: 0;
        line-height: 33px;
        text-align: center;
        margin: 50px 0 30px 0;

        & span {
          color: #57a4ff;
          font-size: 20px;
          letter-spacing: 2.5px;
          line-height: 27px;
          text-align: center;
        }
      }

      & .description {
        color: #3b4750;
        font-size: 16px;
        letter-spacing: 0;
        line-height: 24px;

        & li {
          margin: 5px 0;
        }
      }

      & .actions {
        display: flex;
        justify-content: center;
        position: absolute;
        width: calc(100% - 40px);
        bottom: 13px;
        left: 20px;
      }
    }
  }

  & .support-row {
    margin-top: 100px;
    display: flex;
    padding: 0 15%;

    & .content {
      width: 60%;
      & .title {
        color: #7e8085;
        font-size: 16px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 25px;
      }

      & .subtitle {
        color: #2c2e30;
        font-size: 40px;
        font-weight: 600;
        letter-spacing: 0;
        line-height: 46px;
        margin: 20px 0 15px 0;
      }

      & .text {
        color: #54565a;
        font-size: 18px;
        letter-spacing: 0;
        line-height: 28px;
      }
    }
  }

  & .users-row {
    margin-top: 120px;
    padding: 0 20%;

    & .title {
      color: #2c2e30;
      font-size: 30px;
      font-weight: 600;
      letter-spacing: 0;
      line-height: 55px;
      text-align: center;
      margin: 0 10%;
    }
  }

  & .dark-banner {
    background-color: #151c32;
  }

  & .statistics-row {
    color: #ffffff;
    padding: 50px 10%;

    & .title {
      font-size: 34px;
      // font-weight: 600;
      letter-spacing: 0;
      line-height: 46px;
      text-align: center;
      padding: 0 20%;
    }

    & .statistics {
      display: flex;
      justify-content: space-between;
      margin: 70px 0 30px;

      & .data {
        & .figures {
          font-size: 100px;
          font-weight: bold;
          letter-spacing: 0;
          line-height: 80px;
          text-align: center;
        }

        & .description {
          font-size: 18px;
          letter-spacing: 0;
          line-height: 28px;
          text-align: center;
        }
      }
    }

    & .actions {
      display: flex;
      justify-content: center;

      & .btn {
        width: 200px;
      }
    }
  }

  & .closing-banner {
    padding: 100px 15% 50px;

    & .blog-row {
      & .title {
        color: #2c2e30;
        font-size: 40px;
        font-weight: 600;
        letter-spacing: 0;
        line-height: 55px;
        text-align: center;
      }

      & .cards {
        display: flex;
        justify-content: space-evenly;
      }
    }

    & .ready-row {
      margin-top: 150px;
      display: flex;
      align-items: center;

      & .title {
        color: #2c2e30;
        font-size: 40px;
        font-weight: 600;
        letter-spacing: 0;
        line-height: 46px;
      }

      & .text {
        color: #54565a;
        font-size: 18px;
        letter-spacing: 0;
        line-height: 28px;
        padding-right: 20px;
      }

      & .actions {
      }
    }

    & .links-row {
      margin-top: 150px;

      & .links {
        display: flex;
        justify-content: space-evenly;

        & .column {
          & div {
            color: #54565a;
            font-size: 16px;
            letter-spacing: 0;
            line-height: 39px;

            &:hover {
              cursor: pointer;
            }
          }

          & .title {
            color: #2c2e30;
            font-size: 20px;
            font-weight: 600;

            &:hover {
              cursor: default;
            }
          }
        }
      }
    }
  }

  & .footer {
    height: 74px;
    padding: 0 5%;
    background-color: #f6f8f9;
    display: flex;
    color: #54565a;
    font-size: 14px;
    letter-spacing: 0;
    align-items: center;

    & .guidelines {
      width: 60%;
      &:hover {
        cursor: pointer;
      }
    }

    & .language {
      &:hover {
        cursor: pointer;
      }
    }

    & .social {
      display: flex;
      padding-left: 30px;
      & .bubble {
        height: 25px;
        width: 25px;
        background-color: #51545e;
        border-radius: 50%;
        margin-right: 15px;
        &:hover {
          cursor: pointer;
        }
      }
    }
  }
}

.kpi-view {
  & .panel {
    padding: 16px 0 0;
    & .filters {
      & .filter-content {
        background-color: #2f3b52;
      }
    }

    & .data-display {
      display: flex;
      height: fit-content;

      & .map-area {
        width: 100%;
        position: relative;

        & .iFrame {
          width: 100%;
          height:600px;
        }
      }
    }
  }
}

.gps-view {
  & .panel {
    padding: 16px 0 0;
    & .filters {
      & .filter-content {
        background-color: #2f3b52;
      }
    }

    & .data-display {
      display: flex;
      height: fit-content;

      & .map-area {
        width: 100%;
        position: relative;

        & .play-button {
          position: inherit;
          display: inline-flex;
          height: 30px;
          width: 75px;
          bottom: 65px;
          left: 15px;
          background-color: #fff;
          margin: 4px 6px;
          font-weight: normal;
          color: black;
          padding: 5px 10px;
          border-radius: 5px;
          border: 0px solid;
          box-shadow: 1px 2px 8px 0px #484a50;
          cursor: pointer;
          z-index: 2;
        }

        & .map-view {
          border-radius: 0 0 0 12px;
          width: auto;
          height: 530px;
        }

        & .map-quick-filters {
          position: absolute;
          height: 47px;
          width: 47px;
          border-radius: 50%;
          background-color: #2F3B52;
          left: 15px;
          bottom: 15px;
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: center;

          &.date-filter {
            bottom: 200px;

            & img {
              height: 31px;
            }
          }

          &.trips {
            bottom: 77px;

            & img {
              height: 25px;
            }
          }

          &.zones {
            bottom: 139px;

            & img {
              height: 31px;
            }
          }

          
        }

        & .map-quick-filter-tooltip {
          background-color: #2F3B52;
          padding: 0;
          border-radius: 5px;
          
          &:after {
            border-right-color: #2F3B52;
            left: -6px;
          }

          &.trips {
            & .tooltip-content {
              width: 255px;
            }

            & .description {
              padding-left: 5px;
            }
          }

          & .tooltip-content {
            padding: 10px 15px;
            width: 220px;

            &.trips {
              padding: 10px 15px;
              width: 400px;
            }

            & .title {
              color: #FFFFFF;
              font-size: 17px;
              font-weight: bold;
              letter-spacing: 0;
              line-height: 25px;
            }

            & .body {

              & .date-container {
                width: 100%;
                margin-top: 15px;
              }

              & .separator {
                border-color: white;
                border-bottom-width: 1px;
                border-bottom-style: solid;
                margin-top: 30px;
              }

              & .buttons-container {
                margin-top: 20px;
                width: 100%;
                display: flex;
                justify-content: space-around;
                margin-bottom: 10px;
              }

              & .tooltip-row {
                display: flex;
                height: 20px;
                margin-top: 5px;
                align-items: center;

                & .hide-button {
                  width: 10%;
                  cursor: pointer;

                  & svg {
                    height: 18px;
                  }
                }

                & .color {
                  width: 10px;
                  height: 10px;
                  border-radius: 50%;
                  margin: 0 3%
                }

                & .description {
                  width: 60%;
                  flex-grow: 1;
                }

                & .counter {
                  width: 20%;
                  text-align: end;
                }
              }
            }
          }
        }
      }

      & .collapser {
        position: relative;
        width: 23px;
        height: 30px;
        top: 235px;
        left: -7px;
        border: #a7b6d6 solid 1px;
        background: no-repeat #2f3b52 center;
        background-image: url(/images/collapse-arrows.svg);
        transform: scale(1, 1);
        transition: transform 0.5s ease-in-out;
        cursor: pointer;
      }

      & .side-bar {
        width: 40%;
        height: auto;
        transition: 0.5s ease-in-out;
        max-height: 530px;
        color: white;

        & .tabs {
          height: 100%;
          width: 100%;

          & > div {
            height: 54px;
            border-bottom: 1px solid #fff;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;

            & img {
              height: 30px;

              &.zone {
                height: 36px;
              }
            }
          }
        }

        & .tabs-ribbon {
          background-color: #2f3b52;

          & div {
            width: 50%;
          }
        }

        & .detail-content {
          min-height: 470px;
          max-height: 470px;
          position: relative;
          overflow-x: hidden;
          overflow-y: scroll;

          &.geofences {
            min-height: 410px;
            max-height: 410px;
          }

          & .zone-type {
            color: #FFFFFF;
            font-size: 14px;
            font-weight: bold;
            letter-spacing: 0;
            line-height: 65px;
            border-bottom: 1px solid #d8d8d880;

            & .map-zones-rows {
              & .map-zone-row {
                height: 45px;
                display: flex;
                color: #FFFFFF;
                font-size: 16px;
                letter-spacing: 0;
                line-height: 25px;
                font-weight: normal;
                padding-left: 20px;
                position: relative;

                & .color {
                  // width: 10%;
                  height: 12px;
                  width: 12px;
                  border-radius: 50%;
                  background-color: #7ED321;
                  position: absolute;
                  left: 4px;
                  top: 50%;
                  transform: translateY(-50%);

                  &.type-1 {
                    background-color: #FE8F9D;
                  }

                  &.type-2 {
                    background-color: #FBA61A;
                  }

                  &.type-3 {
                    background-color: #A659E9;
                  }

                  &.type-4 {
                    background-color: #777777;
                  }

                  &.type-5 {
                    background-color: #50C4E3;
                  }
                }

                & .name {
                  width: 70%;
                  overflow: hidden;
                  white-space: nowrap;
                  text-overflow: ellipsis;
                  line-height: 46px;
                }

                & .delete {
                  width: 15%;
                  display:flex;
                  justify-content: center;
                  align-items: center;

                  & img {
                    cursor: pointer;
                  }
                }

                & .edit {
                  width: 15%;
                  display:flex;
                  justify-content: center;
                  align-items: center;

                  & img {
                    cursor: pointer;
                  }
                }
              }
            }
          }

          & .facility-row {
            & .header {
              display: flex;
              align-items: center;
              background-color: #384B76;
              height: 50px;
              font-size: 18px;
              font-weight: bold;
              letter-spacing: 0;
              border-bottom: 1px solid #979797;
              padding-left: 10px;

              & .description {
                width: 60%;
              }

              & .icon {
                width: 15%;
                text-align: end;
              }

              & .counter {
                width: 15%;
                text-align: center;
              }

              & .toggle-icon {
                width: 10%;
                display: flex;
                justify-content: center;
                transition: .4s ease;
                cursor: pointer;
                height: 100%;

                & img {
                  height: 12px;
                  position: relative;
                  top: 40%;
                }
              }
            }

            &.selected {
              & .facility-trucks {
                & .truck-row {
                  height: 45px !important;
                  border-bottom: 1px solid #979797;
                }
              }

              & .header {
                & .toggle-icon {
                  transform: rotate(180deg);
                }
              }
            }

            & .facility-trucks {

              & .truck-row {
                border-bottom: 0px;
                display: flex;
                align-items: center;
                height: 0px;
                transition: 0.4s ease;
                overflow: hidden;

                &.clickable {
                  cursor: pointer;
                }
              }

              & .company-logo {
                width: 20%;
                display: flex;
                justify-content: center;

                & img {
                  height: 30px;
                  border-radius: 50%;
                }
              }

              & .truck-icon {
                width: 20%;
              }

              & .truck-description {
                width: 20%;
                font-size: 14px;
                font-weight: 500;
                letter-spacing: 0;
                line-height: 16px;
              }

              & .gps-icon {
                width: 40%;

                & .cellphone {
                  transform: translateY(4px);
                }

                & .equipment {
                  transform: translate(0px, 8px);
                }
              }
            }
          }
        }

        & .actions-row {
          height: 60px;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        & .detail-area{
          position: relative;

          & .create-zone-button {
            // position: absolute;
            // bottom: 10px;
            // left: 50%;
            // transform: translateX(-50%);
  
            &:focus {
              outline: none;
            }
          }
        }

      }

      &.collapsed {
        & .collapser {
          transform: scale(-1, -1);
          transition: transform 0.5s ease-in-out;
        }
        & .side-bar {
          width: 5%;
          transition: 0.5s ease-in-out;
        }
      }
    }
  }

  &.editing {
    & .gm-style-iw-c {
      & > button {
        display: none !important;
      }
    }
  }

  & .gm-style .gm-style-iw-t::after {
    display: none;
  }

  & .info-window-confirm-item {
    width: 240px;
    height: auto;
    overflow: hidden;

    & .tooltip-content {
      padding: 10px;

      & .title {
        text-align: center;
        padding: 0;
        font-size: 16px;
        font-weight: bold;

        &.dark {
          color: #20293d;
        }
      }

      & .actions {
        margin-top: 15px;
        text-align: center;
        display: flex;
        justify-content: space-around;

        & button {
          width: 100px;
        }
      }
    }
  }

  & .gm-style-iw {
    padding: 0px;
    max-height: none !important;
  }

  & .gm-style-iw-d {
    overflow: hidden !important;
    max-height: none !important;
  }
}

.map-indicators-view {

  & .panel {
    padding: 16px 0 0;
    height: calc(100% - 99px - 60px);
    & .filters {
      & .filter-content {
        background-color: #2f3b52;
      }
    }

    & .data-display {
      display: flex;
      // height: fit-content;
      height: calc(100% - 70px);

      & .map-area {
        width: 100%;
        // height: 530px;
        height: 100%;
        position: relative;
        background-color: grey;

        & .overlay {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-color: #ffffff99;
          z-index: 1;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 40px;
          text-align: center;
          // filter: blur(8px);
        }

        & .resume-panel {
          width: 334px;
          display: flex;
          flex-direction: column-reverse;
          position: absolute;
          bottom: 10px;
          left: 10px;

          & .resume {
            background-color: rgba(47, 59, 82, 0.8);
            padding-left: 10px;
            padding-right: 10px;
            padding-top: 5px;
            width: 360px;
            border-radius: 5px;
            overflow: hidden;

            & .general-resume-content {
              height: calc(100% - 35px);
              width: 100%;
              display: flex;
              flex-direction: column-reverse;

              & .units-tags {
                width: 100%;
                height: 30px;
                margin-bottom: 10px;

                & span {
                  color: white;
                  font-size: 15px;
                  padding: 0 2px;
                }

                & .show span {
                  color: #000;
                  padding: 0 1px;
                }
              }

              & .units-charts {
                width: 100%;
                height: 150px;
                margin-bottom: 10px;
                display: flex;

                & .units-truck {
                  min-width: 40%;
                  max-width: 40%;
                  display: flex;
                  flex-direction: column;
                  color: white;
                  font-size: 10px;
                  justify-content: space-between;
                  height: 100%;
                  font-weight: bold;
                }

                & .units-data {
                  width: 100%;
                  justify-content: center;
                  align-items: center;
                  display: flex;
                  flex-direction: column;

                  & .chart-container {
                    width: 100%; 
                    height: 100%; 
                    color: white; 
                    display: flex; 
                    flex-direction: column; 
                    align-items: center;
                    justify-content: flex-end;

                    & .chart-legend {
                      width: 80%;
                      display: flex;

                      &  > div {
                        width: 50%;
                        text-align: right;
                        padding-right: 10%;
                      }
                    }
                    
                    // .chart-legend

                    & .chart {
                      color: white;
                      display: flex;
                      justify-content: center;
                      align-items: center;
                      width: 80%;
                      min-height: 10%;

                      & .chart-legend {
                        width: 100%;
                      }

                      &:last-child {
                        border-bottom-left-radius: 5px;
                        border-bottom-right-radius: 5px;
                      }

                      &:first-child {
                        border-top-left-radius: 5px;
                        border-top-right-radius: 5px;
                      }
                    }
                  }

                }

              }

              & .units-section {
                background-color: rgba(121, 163, 255, 0.8);
                border-radius: 5px;
                height: 55px;
                display: flex;

                & .units-truck {
                  min-width: 40%;
                }

                & .units-data {
                  width: 100%;
                  justify-content: center;
                  align-items: center;
                  display: flex;
                  flex-direction: column;

                  & .title {
                    font-size: 10px;
                    color: white
                  }

                  & .units {
                    font-size: 20px;
                    color: white
                  }

                }

              }

            }

            & .resume-title {
              display: flex;
              justify-content: space-between;
              color: white;
              font-size: 14px;

              & .open-indicator {
                font-size: 20px;
                margin-top: -5px;
                color: rgba(74, 144, 226, 1);
                cursor: pointer;
              } 

            }

            & .row-content {
              display: flex;

              & .main-space {
                min-width: 37%;
                max-width: 37%;
                display: flex;
                align-items: center;
                justify-content: center;

                & .config {
                  position: relative;
                  cursor: pointer;

                  & .options-container {
                    position: absolute;
                    cursor: auto;
                    min-width: 200px;
                    padding: 10px;
                    border-radius: 5px;
                    position: absolute;
                    background-color: #fff;
                    // left: 0;
                    // bottom: 0;
                    // transform: translateX(-100%);
                    box-shadow: 0px 0px 19px 0px #0000007a;
                    font-size: 14px;
                    transition: .2s ease;

                    right: -5px;
                    bottom: 50%;
                    transform: translate(100%, 50%);
                  }
                }
              }

              & .space {
                width: 100%;
                color: rgba(255, 255, 255, 0.8);
                font-size: 10px;
                text-align: center;
              }

            }

            & .content-data {
              width: calc(100% + 13px);
              height: 73%;
              margin-top: 8px;
              overflow-y: scroll;

              & .row-content{
                transition: .3s ease-in-out;
                &:hover {
                  // transition: .3s ease-in-out;
                  background-color: #415479;
                }
              }

              & .main-space {
                width: 37%;
                height: 25px;
                display: flex;
                align-items: center;
                color: white;
                font-size: 11px;

                & .tag {
                  height: 20px;
                  border-radius: 5px;
                  color: white;
                  font-size: 11px;
                  display: flex;
                  align-items: center;
                  padding-left: 5px;
                  padding-right: 5px;
                  width: fit-content;
                }

              }

              & .space {
                width: 100%;
                color: rgba(255, 255, 255, 1);
                font-size: 11px;
                text-align: center;
                height: 25px;
                display: flex;
                justify-content: center;
                align-items: center;
              }

            }

          }

        }

        & .map-view {
          border-radius: 0 0 0 12px;
          width: auto;
          height: 530px;
        }

        & .kpi-tooltip {
          background-color: #fff;
          padding: 15px 15px;
          border-radius: 3px;
          font-family: Overpass;
          font-style: normal;
          font-size: 16px;
          color: #4E4E4E;
          min-width: 240px;
          font-weight: bold;
          transform: translate(18px, -50%);

          &.long {
            min-width: 350px;
          }

          &:before {
            transform: translateY(-50%) rotate(45deg);
            width: 17px;
            content: "";
            height: 17px;
            position: absolute;
            left: -9px;
            top: 50%;
            background-color: #fff;
          }

          & .close {
            position: absolute;
            top: 5px;
            right: 10px;
            cursor: pointer;
            color: #3e4e6c80;
          }

          & .title {
            font-size: 20px;
            border-bottom: 1px solid #20293D80;
            line-height: 35px;
          }

          & .tooltip-body {
            // display: flex;
            padding-top: 20px;
            padding-bottom: 15px;

            & .indicators,
            & .full-indicators {
              padding-right: 10px;
              width: 70%;
              line-height: 35px;
              border-right: 1px solid #20293D80;
              & > div {
                display: flex;
                justify-content: space-between;
              }

              & .orange {
                color: #EC5E0E;
              }

              & .green {
                color: #A2B80B;
              }

              & .yellow {
                color: #F3DD15;
              }

              & .red {
                color: #EC0E0E;
              }
            }

            & .full-indicators {
              width: 100%;
              border-right: none;
            }

            & .counter {
              width: 30%;
              text-align: center;
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: space-around;
              
              & span {
                font-size: 28px;
              }

              & div {
                line-height: 20px;
              }
            }

            & .tooltip-group-container {
              display: flex;

              &+ .tooltip-group-container {
                border-top: 1px solid #20293D80;
                margin-top: 5px;
                padding-top: 5px;
              }
            }
          }

          & .actions {
            text-align: center;
            font-weight: 600;
            font-size: 15px;
            line-height: 12px;
            /* identical to box height, or 80% */
            cursor: pointer;

            color: #4184F9;

            opacity: 0.8;
          }
        }

      }

      & .collapser {
        // position: relative;
        position: absolute;
        width: 23px;
        height: 30px;
        top: 9px;
        left: -8px;
        border: #a7b6d6 solid 1px;
        background: no-repeat #2f3b52 center;
        background-image: url(/images/collapse-arrows.svg);
        transform: scale(1, 1);
        transition: transform 0.5s ease-in-out;
        cursor: pointer;
      }

      & .config-toggle {
        position: absolute;
        right: 10px;
        bottom: 60px;
        background-color: #bfd0dc;
        color: #000;
        height: 45px;
        width: 45px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        cursor: pointer;

        & .options-container {
          // height: 250px;
          cursor: auto;
          width: 200px;
          padding: 10px;
          border-radius: 5px;
          position: absolute;
          background-color: #fff;
          // border: 1px solid #000;
          left: 0;
          bottom: 0;
          transform: translateX(-100%);    
          box-shadow: 0px 0px 19px 0px #0000007a;
          font-size: 14px;
          transition: .2s ease;

          & .title {
            font-size: 16px;
            font-weight: bold;
            border-bottom: 1px solid;
          }

          & .subtitle {
            font-size: 15px;
            margin: 5px 0 3px;
          }

          & .radioflex {
            margin-left: 11px;
          }

          & label {
            cursor: pointer;
          }

          & input {
            margin: 0 0 4px 0;
            cursor: pointer;
          }
        }
      }

      & .full-toggle {
        position: absolute;
        right: 10px;
        bottom: 10px;
        background-color: #bfd0dc;
        color: #000;
        height: 45px;
        width: 45px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        cursor: pointer;
      }
      
      & .size-toggle {
        position: absolute;
        right: 10px;
        bottom: 60px;
        color: #0000005e;
        font-size: 16px;
        font-weight: bold;

        &.theme {
          // right: 5px;
          bottom: 158px;
          font-size: 13px;

          // & div {
          //   // width: 60px;
          // }
        }

        & div {
          cursor: pointer;
          background-color: #fff;
          width: 50px;
          height: 23px;
          display: flex;
          align-items: center;
          justify-content: center;
          transition: .5s ease;

          &:hover{
            background-color: #e0efff;
          }

          &.selected {
            color: #2f4ff1;
          }

          &:first-child {
            border-radius: 3px 3px 0 0;
          }

          &:last-child {
            border-radius: 0 0 3px 3px;
          }
        }
      }

      & .side-bar {
        width: 55%;
        // height: auto;
        height: 100%;
        transition: 0.5s ease-in-out;
        // max-height: 530px;
        color: white;
        background-color: #2f3b52;
        position: relative;

        & .title-container {
          display: flex;

          & > div {
            width: 100%;
          }
        }

        & .title {
          display: flex;
          justify-content: center;
          font-size: 14;
          margin-top: 14px;
          margin-bottom: 7px;
          cursor: pointer;
        }

        & .line-title {
          height: 4;
          background-color: rgba(61, 119, 247, 1);
          margin-left: 20%;
          margin-right: 20%;
          margin-bottom: 10px;
        }

      }

      &.full {
        position: absolute;
        top: 0;
        left: 1px;
        width: calc(100% - 1px);
        height: 100%;
        z-index: 999999;
        overflow: hidden;
      }

      &.collapsed {
        & .collapser {
          transform: scale(-1, -1);
          transition: transform 0.5s ease-in-out;
        }
        & .side-bar {
          width: 0%;
          transition: 0.5s ease-in-out;
        }
      }
    }
  }
}



.gps-tooltip-content {
  position: relative;
  min-width: 350px;

  & .gps-tooltip-title {
    background-color: #2F3B52;
    box-shadow: 0 2px 20px 0 rgba(0,0,0,0.24);
    display: flex;
    padding: 12px;
    border-radius: 8px 8px 0 0;
    & .avatar {
      width: 20%;
      display: flex;
      align-items: center;
      justify-content: center;

      & img {
        height: 40px;
        border-radius: 50%;
      }
    }

    & .description {
      width: 60%;
      color: #fff;

      & .company {
        font-size: 18px;
        font-weight: 300;
        letter-spacing: 0;
        line-height: 28px;
      }

      & .status {
        font-size: 14px;
        font-weight: 800;
        letter-spacing: 0;
        line-height: 22px;
      }
    }

    & .type {
      width: 20%;
      display: flex;
      flex-direction: column;
      align-items: center;

      & .icon {
        & svg {
          width: 30px;
        }

        &.full svg{
          width: 40px;
        }
      }

      & .description {
        font-size: 14px;
        font-weight: 800;
        text-align: center;
      }
    }
  }

  & .gps-tooltip-header {    
    padding: 12px 20px 0;
    & .gps-icon {
      width: 40%;
      min-width: 40%;

      & .cellphone {
        transform: translateY(4px);
      }

      & .equipment {
        transform: translate(0px, 8px);
      }
    }

    & .vehicle-info,
    & .equipment-info,
    & .driver-info {
      display: flex;

      & .text-exceed .plus {
        top: 30%;
        line-height: 12px;
      }

      & .icon {
        margin-right: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      & .description {
        color: #4A4A4A;
        font-size: 18px;
        font-weight: 300;
        letter-spacing: 0;
        line-height: 28px;
        overflow: hidden;
        max-width: 280px;
        white-space: nowrap;
        text-overflow: ellipsis;
        width: 100%;
      }
    }
  }

  & .gps-tooltip-trips {
    padding: 12px 20px;
    & .trip-row {
      border-top: 1px solid #979797;
      border-bottom: 1px solid #979797;
      & + .trip-row {
        border-top: 0px;
      }

      &.selected {
        & .trip-header {
          background-color: #0081fc38;
        }

        & .trip-info {
          height: 56px;
        }
        & .trip-time {
          height: 29.6px;
        }
      }

      & .trip-header {
        display: flex;
        min-height: 48px;
        cursor: pointer;

        &:hover {
          background-color: #0081fc38;
        }
        & .icon {
          width: 20%;
          display: flex;
          align-items: center;
          justify-content: center;

          & img {
            height: 28px;
          }
        }
  
        & .description {
          width: 60%;
          line-height: 48px;
          color: #252E47;
          font-size: 14px;
          font-weight: 800;
          letter-spacing: 0;
        }
  
        & .toggle-icon {
          width: 20%;
          display: flex;
          justify-content: center;
          align-items: center;

          & div {
            height: 30px;
            width: 30px;
            border-radius: 50%;
            background-color: #0081FC;
            color: #fff;
            font-weight: bold;
            text-align: center;
            font-size: 30px;
            line-height: 30px;
          }
        }
      }
      & .trip-info {
        background-color: #F6F6F9;
        padding: 0 5px;
        height: 0px;
        overflow: hidden;
        transition: 0.5s;

        & > div {
          color: #4A4A4A;
          font-size: 14px;
          letter-spacing: 0;
          line-height: 28px;
          display: flex;

          & div {
            width: 50%;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
          }

          & span {
            font-weight: 800;
          }
        }
      }

      & .trip-time {
        display: flex;
        background-color: #98acc16b;
        height: 0px;
        overflow: hidden;
        transition: 0.5s;

        & div {
          width: 50%;
          line-height: 30px;
          text-align: center;
          color: #252E47;
          font-size: 14px;
          font-weight: 800;
          letter-spacing: 0;
        }
      }
    }
  }
}

.tax-address-tab {
  color: #fff;

  & .title {
    font-size: 22px;
    font-weight: bold;
    line-height: 60px;
  }

  & .add-icon {
    // position: absolute;
    // right: 0px;
    // bottom: 0px;
    width: 25px;
    height: 25px;
    border-radius: 15px;
    background: linear-gradient(139.24deg, #2f4ff1 0%, #4184f9 100%);
    text-align: center;
    color: #fff;
    font-weight: bold;
    font-size: 25px;
    line-height: 30px;
    cursor: pointer;
  }

  & .address-list {
    & .address-row {
      padding: 0 10px 20px;
      & + .address-row {
        border-top: 1px solid;
      }

      & .actions {
        & button {
          margin: 10px;
        }
      }

      & .icon-container {
        display: flex;
        align-items: center;

        & img {
          margin: 0 5px;
        }

        & svg {
          margin: 0 5px 0 10px;
        }
      }

      & .edit-btn {
        cursor: pointer;
      }
    }
  }
}

.tax-address-form-view {
  color: #fff;
  & .panel {
    display: flex;

    & > div {
      width: 100%;
      padding: 15px;
    }

    & .right-side {
      background-color: #3D77F7;
      border-radius: 0 12px 12px 0;

      & .step-title {
        margin-bottom: 10px;
      }

      & .text-input,
      & .select-input {
        & label {
          color: #ffffffb3 !important;
        }
        & input,
        & select {
          color: #fff !important;
        }
      }
      
      & button {
        width: auto;
      }

      & .file-upload-segment {
        justify-content: space-around;

        & .file-upload-container {
          height: 200px;    
          display: flex;
          flex-direction: column;
          align-items: center;
          
          & .files-container {
            height: 160px;    
            display: flex;
            align-items: center;
          }

          & .file-selector {

          }
        }
      }
    }

    & .left-side {
      // & .select-input select.has-input {
      //   color: #fff;
      // }

      & .radio-input {
        color: #fff;
        margin: 0 10px;
      }

      & .text-input{
        & input {
          color: #fff !important;
        }
      }
      & .select-input {
        // & label,
        & select {
          color: #fff !important;
        }
      }

    }
  }

  & .extra-info {
    margin-top: 3px;
    & p {
      margin: 0;
      font-size: 13px;
    }
  }

  & .actions {
    display: flex;
    justify-content: flex-end;

    & button {
      margin: 0 5px;
    }
  }
}

.billing-form-view {
  & input:-internal-autofill-selected {
    appearance: menulist-button;
    background-image: none !important;
    background-color: transparent !important;
    color: #9ba1ac !important;
  }
  & .taxingAddressInfo {
    border: 1px solid #5A6372;
    border-radius: 12px;
    width: 100%;
    margin: 10px;
    padding: 0 15px 5px;
    color: #fff;
    /* max-width: 100%; */
    box-sizing: border-box;
  }

  & .detailInfo > div div {
    margin-right: 40px;
  }

  & .step-title {
    margin-top: 20px;
  }

  & .section-subtitle {
    font-weight: bold;
    font-size: 16px;
    line-height: 25px;
    color: #FFFFFF;
    width: 100%;
    text-align: end;
  }

  & .taxingAddressInfo div {
      line-height: 28px;
  }

  & .svg-container {
    display: flex;
    align-items: center;
    margin: 0 25px;
  }

  & .resume-header {
      background-color: #3D5074;
      display: flex;
      border-bottom: 2px solid #d8d8d880;
      height: 60px;
      align-items: center;
      /* color: #AFC8FF; */
      font-weight: bold;
      /* font-size: 16px; */
      /* line-height: 25px; */
  /* identical to box height */
      color: #AFC8FF;
      border-radius: 10px 10px 0 0;
  }

  & .products-resume {
      font-weight: bold;
      /* font-size: 16px; */
      /* line-height: 25px; */
      color: #AFC8FF;
      border-radius: 10px 10px 0 0;
  }

  & .resume-body .resume-row {
      display: flex;
      height: 50px;
      align-items: center;

      & > div {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
  }

  & .resume-total {
      display: flex;
      height: 60px;
      align-items: center;
      
      & > div {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
  }

  & .location-info {
    color: #fff;

    & + .location-info {
      margin-top: 20px;
    }

    & .address-title {
      display: flex;
      justify-content: space-between;
    }

    & .address-info {
      background-color: #20293D;
      border: 1px solid #5A6372;
      border-radius: 12px;
      padding: 10px;
      & > div {
        display: flex;
        justify-content: space-between;
        line-height: 23px;
      }
    }
  }
}

.order-detail-view {
  & .order-number {
    font-size: 29px;
    margin-left: 10px;
  }

  & .order-detail-main-status {
    font-size: 20px;
  }

  & .param {
    color: #afc8ff;
    font-weight: bold;
  }

  & .data {
    display: flex;

    & .select-input {
      padding-top: 0;
      margin: 0;
      margin-right: 20px;
      transform: translateY(-15px);
      width: 40%;
      flex-basis: auto;

      & select {
        color: #fff;
      }

      &.order {
        margin-left: 20px;
        width: 200px;
        transform: translateY(-6px);

        & select {
          font-size: 20px;
          font-weight: bold;
        }
      }
    }

    & .text-input {
      width: 60%;
      padding-top: 0;
      margin: 0;
      // transform: translateY(-15px);
      margin-left: 10px;
      transform: translateY(-4px);

      & input {
        color: #fff;
      }
    }
  }

  & .panel {
    min-height: 324px;
    color: #fff;
    position: relative;

    & .edit-btn {
      position: absolute;
      top: 10px;
      right: 15px;

      &:hover {
        cursor: pointer;
      }
    }

    & .trip {
      display: flex;
      margin: 40px 0;

      & .trip-stop {
        display: flex;
        width: 33%;

        & .avatar-container {
          margin: 0 46px;
          & .avatar {
            width: 80px;
            border-radius: 50%;
          }
        }

        & .credentials {
          padding-top: 7px;

          & .location {
            opacity: 0.8;
            color: #ffffff;
            font-size: 24px;
            position: relative;

            & .location-pin {
              position: absolute;
              left: -25px;
              top: 15%;
            }
          }

          & .param {
            opacity: 0.8;
            font-weight: normal;
            font-size: 14px;
            margin: 10px 0 5px 0;
          }

          & .event-date {
            font-size: 14px;
          }
        }
      }

      & .transit-line {
        width: 33%;
        position: relative;
        display: flex;

        & .arrow-base {
          height: 8px;
          width: 8px;
          background-color: #afc8ff;
          border-radius: 50%;
          position: absolute;
          left: 0;
          top: 50%;
          transform: translate(-50%, -50%);
        }

        & .arrow-body {
          width: 95%;
        }

        & .arrow-end {
          height: 8px;
          width: 8px;
          background-color: #afc8ff;
          border-radius: 50%;
          position: absolute;
          right: 0;
          top: 50%;
          transform: translate(50%, -50%);
        }
      }
    }

    & .details {
      display: flex;
      margin: 0 46px;

      & .info {
        display: flex;
        width: 60%;

        & .left,
        & .right {
          width: 50%;
          line-height: 40px;

          & .param {
            margin-right: 10px;
          }

          & .data {
            & .text-input {
              margin: 0;
              transform: translateY(-15px);
            }
          }

          & .logo {
            width: 30px;
            border-radius: 50%;
            margin-right: 10px;
            transform: translateY(20%);
          }
        }
      }

      & .tags {
        display: flex;
        width: 40%;

        & .tags-container {
          max-height: 103px;
          overflow: auto;

          & .tag {
            cursor: pointer;
          }
        }

        & > div {
          width: 50%;
          margin-right: 15px;

          & .param {
            margin: 10px 0 10px 0;
          }
        }
      }
    }

    & .actions {
      display: flex;
      justify-content: flex-end;

      & .btn {
        margin: 0 10px;
      }
    }
  }

  & .tabs {
    margin: 0 -50px -60px -50px;

    & .tabs-row {

    }

    & .tab-content {
      background-color: #fff;
      padding: 20px 5%;
      min-height: calc(100% - 500px);
      & .tab-body {
        &.hidden-tab {
          visibility: hidden;

          & .map-view {
            visibility: hidden;
          }
        }
        & .header {
          color: #3F4549;
          font-size: 19px;
          font-weight: bold;
          border-bottom: 2px solid #EBEEF0;
          line-height: 35px;
          margin-bottom: 10px;
        }
      }

      & .history {   
        & .container-ribbon {
          position: relative;
          & .header-actions {
            position: absolute;
            right: 10px;
            top: 50%;
            transform: translateY(-50%);
          }  
        }   
        & .history-rows {
          & .history-row {
            min-height: 70px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            color: #3D5074;
            font-size: 14px;
            font-weight: bold;
            padding: 5px 40px 5px 3%;
            border-bottom: 1px solid #D5DEEC;
            position: relative;

            &.b {
              background-color:#98bcdf3b;
            }
            &.w {

            }
            &.i {
              // background-color:#dad6d63b;
              opacity: .3;
            }

            & .owner-icon {
              position: absolute;
              left: 10px;
              bottom: 50%;
              transform: translateY(50%);

              & img {
                height: 20px;
                transform: translateX(-2px);
              }
            }

            & .user-info-container {
              display: flex;
              align-items: center;
              font-size: 13px;
              font-weight: normal;

              & img {
                height: 23px;
                border-radius: 50%;
              }

              & div {
                margin-left: 5px;
              }

              & svg {
                margin: 0 5px;
                position: relative;
                top: 4px;
              }
            }

            & .delete-icon {
              position: absolute;
              right: 10px;
              cursor: pointer;
            }
          }
        }
      }

      & .map-tab {
        & .map-area {
          position: relative;

          & .play-button {
            position: inherit;
            display: inline-flex;
            height: 30px;
            width: 75px;
            bottom: 65px;
            left: 15px;
            background-color: #fff;
            margin: 4px 6px;
            font-weight: normal;
            color: black;
            padding: 5px 10px;
            border-radius: 5px;
            border: 0px solid;
            box-shadow: 1px 2px 8px 0px #484a50;
            cursor: pointer;
            z-index: 2;
          }

          & .map-view {
            height: 500px;
            z-index: 1;
          }
        }
      }

      & .comments-tab {
        & .comments-container {
          max-height: 500px;
          overflow-y: auto;
          border-bottom: 2px solid #EBEEF0;
          margin-bottom: 10px;
        }

        & .comment {
          padding-left: 100px;
          position: relative;
          min-height: 48px;
          padding-bottom: 20px;

          &.secondary {
            margin-left: -30px;
          }

          &.section {
            margin-bottom: 20px;
            margin-right: 20px;
            border: 1px solid rgba(220,220,220, 1);
            box-shadow: 5px 5px rgba(220,220,220, 1);
            border-radius: 15px;
          }

          & .avatar-container {
            position: absolute;
            z-index: 2;
            left: 33px;
            & img {
              border-radius: 50%;
              height: 48px;
              width: 48px;
            }
          }

          & .fav-comments {
            width: 90%;
            // height: 300px;
            position: absolute;
            // bottom: 0;
            top: 0;
            // transform: translateY(100%);
            transform: translateY(-100%);
            background: #fff;
            box-shadow: -2px -3px 11px 4px #33333369;
            border-radius: 5px;
            padding: 10px 3%;
            box-sizing: border-box;
            z-index: 10;

            & .panel-title {
              font-style: normal;
              font-weight: bold;
              font-size: 14px;
              line-height: 21px;

              color: #5E6E8F;
            }

            & .message-container {
              max-height: 200px;
              overflow-y: auto;
              margin-bottom: 10px;
              & .option {
                margin: 10px 0;
                display: flex;
              }
              & label {
                width: 90%;
              }

              & img {
                margin: auto;
                cursor: pointer;
              }
            }

            & .new-container {
              margin-bottom: 10px;
              padding-top: 3px;
              & textarea {
                margin-left: 20px;
                padding-top: 1px;
              }
            }

            & .actions {
              display: flex;
              align-items: center;
              justify-content: flex-end;

              & button {
                height: 30px;
                width: 120px;
              }
            }
          }

          &.comment-input {
            height: 48px;
            padding-bottom: 0px;
            margin-bottom: 50px;
          }
          & textarea {
            width: 100%;
            height: 100%;
            padding-right: 100px;
          }
          & .send-icon,
          & .fav-icon,
          & .attach-icon {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            cursor: pointer;
          }
          & .attach-icon {
            right: 75px;
          }
          & .send-icon {
            right: 10px;
          }
          & .fav-icon {
            right: 50px;
          }

          & .comment-info {
            display: flex;
            position: relative;
            z-index: 2;
            & .user-name {
              font-weight: bold;
              font-size: 13px;
              color: #2E9FFF;
            }
            & .divider {
              height: 3px;
              width: 3px;
              background-color: #A7B4BA;
              border-radius: 50%;
              margin: auto 6px;
            }
            & .date-info {
              font-weight: normal;
              font-size: 13px;
              color: #A7B4BA;
            }
          }
          & .comment-container {
            font-style: normal;
            font-weight: normal;
            font-size: 15px;
            line-height: 21px;
            color: #3F4549;
            position: relative;
            z-index: 1; 
          }

          & .comment-options {
            color: #00273b59;
            font-size: 13px;
            cursor: pointer;
            width: fit-content;
            // margin-left: 8px;
            display: flex;
            position: relative;
            z-index: 2;

            & .divider {
              height: 3px;
              width: 3px;
              background-color: #A7B4BA;
              border-radius: 50%;
              margin: auto 6px;
            }
          }
        }
      }

      & .products {
        & .table-header {
          display: flex;
          border-bottom: 1px solid #979797;
          margin-top: 20px;

          & div {
            font-weight: bold;
            font-size: 14px;
            line-height: 21px;
            color: #20293dcc;
            text-align: center;
          }
        }

        & .table-body {
          max-height: 200px;
          overflow-y: auto;
          border-bottom: 1px solid #979797;

          & .row-container {
            position: relative;
            padding-left: 30px;
            transition: .4s ease;
            &:hover {
              background-color: #e9f5ff;
            }

            &.conflict {
              background-color: #ffd3d3 !important;

              & .product-row:hover {
                background-color: #ffd3d3;
              }
            }

            & .checkbox-container {
              position: absolute;
              top: 50%;
              left: 0;
              transform: translateY( -11px);
            }
          }
        }

        & .resume {
          display: flex;
          & div {
            font-weight: bold;
            font-size: 18px;
            line-height: 31px;
            color: #20293d;
            text-align: center;
          }
        }

        & .actions {
          display: flex;
          justify-content: flex-end;
        }
      }
    }
  }
}

.facilities-control-view {
  padding: 16px 2% 60px !important;
}

// & .title {
//   color: #ffffff;
//   font-size: 24px;
//   font-weight: bold;
//   line-height: 37px;
//   margin-bottom: 10px;
// }

// & .schedules-data {
//   min-height: 400px;
// }

// & .days-container {
//   color: #ffffff;
//   font-size: 20px;
//   font-weight: bold;
//   line-height: 80px;
//   background-color: #000000;
//   display: flex;
//   padding-left: 40px;
//   padding-right: 40px;

//   & .day {
//     flex: 1;
//     text-align: center;
//     cursor: pointer;
//   }

//   & .festivos {
//     flex: 2;
//   }

//   & .current {
//     border-bottom: 2.5px solid #e9643f;
//   }
// }

// & .schedule-container-header {
//   width: 100%;
//   height: 30px;

//   & .left-panel {
//     width: 10%;
//     float: left;
//   }

//   & .right-panel {
//     width: 90%;
//     float: right;
//     overflow: auto;
//     display: flex;
//     color: white;
//     cursor: grab;
//     text-align: center;

//     & .schedule-container-title {
//       flex: 1;
//       min-width: 132px;
//       padding: 1px;
//       margin-top: 20px;
//     }
//   }
// }

// & .schedule-container-content {
//   margin-top: 15px;

//   & .left-panel {
//     width: 10%;
//     float: left;
//     overflow-x: hidden;
//     height: 390px;
//     cursor: grab;
//     margin-top: 2px;

//     & :last-child {
//       border-width: 1px 0 1px 1px !important;
//     }

//     & :nth-child(odd) {
//       border-width: 0 0 0 1px !important;
//     }

//     & :first-child {
//       border-width: 1px 0 0 1px !important;
//     }

//     & .hours {
//       height: 30px;
//       width: 100%;
//       color: white;
//       border-width: 1px 0 1px 1px;
//       border-style: solid;
//       border-color: #2f3b52;
//       line-height: 20px;
//       text-align: center;
//     }
//   }

//   & .right-panel {
//     width: 90%;
//     float: right;
//     overflow: hidden;
//     display: flex;
//     height: 390px;

//     & .schedule-container-column {
//       width: 100%;
//       min-width: 134px;
//       flex: 1;
//       position: relative;

//       &:last-child>.schedule-container-data-container>.schedule-container-data {
//         border-right-width: 1px !important;
//       }

//       & .schedule-container-data-container {
//         & .schedule-container-data {
//           height: 30px;
//           border-width: 1px 0 1px 1px;
//           border-style: solid;
//           border-color: #46526a;
//         }

//         & :first-child {
//           border-width: 1px 0 0 1px !important;
//         }

//         & :last-child {
//           border-width: 1px 0 1px 1px !important;
//         }

//         & :nth-child(odd) {
//           border-width: 0 0 0 1px;
//           // border-width: 0 0 0 2px;
//           // border-style: solid;
//           // border-color: gray;
//         }

//         & :nth-child(even) {
//           border-top-color: rgba(70, 82, 106, 0.3);
//           // border-width: 0 0 0 2px;
//           // border-style: solid;
//           // border-color: gray;
//         }
//       }

//       & .schedule-container-item {
//         position: absolute;
//         left: 0;
//         right: 0;
//         //height: 61px; //32.5px one element
//         background-color: rgba(0, 110, 255, 0.836);
//         border-radius: 5px;
//         -moz-user-select: none;
//         -khtml-user-select: none;
//         -webkit-user-select: none;
//         user-select: none;
//         cursor: pointer;

//         & span {
//           position: absolute;
//           display: inline-block;
//           width: 100%;
//           color: white;
//           text-align: center;
//           font-size: 15px;
//           top: 50%;
//           left: 50%;
//           transform: translate(-50%, -50%);
//         }

//         & span.close {
//           top: 10px;
//           text-align: right;

//           & a {
//             margin-right: 5px;
//             color: lightgray;
//             text-decoration: none;
//           }
//         }
//       }
//     }
//   }
// }
// }

.select-input-styled {
  & .list {
    // height: 60%;
    height: 40px;
    display: flex;
    align-items: center;
    position: relative;
    margin: auto;
    padding-top: 15px;
    padding-bottom: 3px;

    &.filtered {
      // background-color: #526a99;
      // border-radius: 5px;
    }

    & .select-title {
      // color: #ffffff;
      font-size: 14px;
      line-height: 22px;
      min-height: 22px;
      // margin-right: 5px;
      // display: flex;
      overflow: hidden;
      margin-right: 30px;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    & .caret {
      position: absolute;
      right: 0%;
      top: 60%;
      transform: rotate(0deg);
      transition: transform 0.5s ease-in-out;
    }

    &.open {
      & .caret {
        transform: rotate(180deg);
        transition: transform 0.5s ease-in-out;
      }

      & .select-content {
        display: flex;
      }
    }

    & .select-content {
      position: absolute;
      padding: 0;
      border: 1px solid #a4b4ce;
      box-sizing: border-box;
      top: 45px;
      left: 0;
      border-radius: 12px;
      width: 100%;
      // max-height: 270px;
      max-height: 287px;
      flex-wrap: wrap;
      background-color: white;
      z-index: 4;
      display: none;
      padding: 20px;
      overflow: hidden;

      & .separator {
        width: 100%;
        height: 0px;
        border: solid 0.5px #a4b4ce;
        margin-bottom: 5px;
      }

      & .deselector {
        z-index: 3;
        position: absolute;
        top: 8px;
        right: 15px;
        color: #5097d5;
        font-size: 12px;
        font-weight: bold;
        cursor: pointer;
      }

      & .search-list {
        width: 100%;

        & .text-input {
          width: 100%;
          margin: 0;
          top: -20px;
        }
      }

      & .checkmark {
        width: 20px;
        height: 20px;
      }

      & .single {
        padding-left: 0px;
        cursor: default;

        & .checkmark {
          display: none;
        }
      }

      & .items {
        width: 100%;
        // height: auto;
        display: flex;
        overflow-y: auto;
        overflow-x: hidden;
        max-height: 170px;

        &.chips {
          flex-wrap: wrap;
        }

        &.checks {
          flex-flow: column;
        }

        & .sub-item {
          margin-left: 15px;
        }

        & label {
          position: relative;

        }
      }
    }
  }
}

.filter-content {
  height: 70px;
  background-color: #242e42;
  display: flex;

  & .filter {
    width: 80%;
    height: 100%;
    display: flex;
    justify-content: space-evenly;

    &.full {
      width: 95%;
    }

    & .list {
      height: 60%;
      display: flex;
      align-items: center;
      position: relative;
      margin: auto;

      &.filtered {
        background-color: #526a99;
        border-radius: 5px;
      }

      & .title {
        color: #ffffff;
        font-size: 14px;
        line-height: 22px;
        margin-right: 5px;
        display: flex;
      }

      & .caret {
        transform: rotate(0deg);
        transition: transform 0.5s ease-in-out;
      }

      &.open {
        & .caret {
          transform: rotate(180deg);
          transition: transform 0.5s ease-in-out;
        }

        & .content {
          display: flex;
        }
      }

      & .content {
        position: absolute;
        padding: 0;
        border: 1px solid #a4b4ce;
        box-sizing: border-box;
        top: 70%;
        left: 70%;
        border-radius: 12px;
        width: 280px;
        max-height: 460px;
        flex-wrap: wrap;
        background-color: white;
        z-index: 9999;
        display: none;
        padding: 20px;
        overflow: hidden;

        &.date {
          padding: 0;
          width: 244px;

          & .deselector {
            // top: 0;
            top: 44px;
            line-height: 30px;
          }

          & .items {
            overflow-y: hidden !important;
          }
        }

        & .separator {
          width: 100%;
          height: 0px;
          border: solid 0.5px #a4b4ce;
          margin-bottom: 5px;
        }

        & .deselector {
          z-index: 3;
          position: absolute;
          top: 8px;
          right: 15px;
          color: #5097d5;
          font-size: 12px;
          font-weight: bold;
          cursor: pointer;
        }

        & .search-list {
          width: 100%;

          & .text-input {
            width: 100%;
            margin: 0;
            top: -20px;
          }
        }

        & .checkmark {
          width: 20px;
          height: 20px;
        }

        & .items {
          width: 100%;
          // height: auto;
          display: flex;
          overflow-y: auto;
          overflow-x: hidden;
          max-height: 300px;

          &.chips {
            flex-wrap: wrap;
          }

          &.checks {
            flex-flow: column;
          }

          & .sub-item {
            margin-left: 15px;
          }
        }
      }
    }

    & .filter-button {
      align-self: center;

      & .btn {
        height: 32px;
        width: 80px;
      }
    }
  }

  & .search {
    height: 100%;
    width: 24%;
    display: inline-flex;
    padding: 0px 30px;
    box-sizing: border-box;
    position: relative;
    top: -10px;

    & .icono {
      display: block;
      position: absolute;
      bottom: 12;
      transform: translateX(-100%);
      right: 20;
    }
  }

  // & .left {
  //   height: 100%;
  //   width: 75%;
  //   display: inline-flex;
  //   vertical-align: top;
  //   padding: 17px 0px;
  //   padding-left: 18px;
  //   box-sizing: border-box;
  //   position: relative;

  //   & .list {
  //     display: inline-flex;
  //     color: #ffffff;
  //     width: 90%;
  //     justify-content: space-between;
  //     list-style-type: none;
  //     font-size: 15px;

  //     & .need-flex {
  //       display: inline-flex;

  //       & .elementosli {
  //         display: flex;
  //       }

  //       &.withitems {
  //         background: linear-gradient(139.24deg, #2f4ff1 0%, #4184f9 100%);
  //         padding: 2px 4px;
  //         border-radius: 4px;
  //       }

  //       & .caret {
  //         margin: 0 10px;
  //       }

  //       &.open {
  //         & .caret {
  //           transform: rotate(360deg);
  //           transition: transform 0.5s ease-in-out;
  //         }

  //         &.withitems {
  //           background: linear-gradient(139.24deg, #2f4ff1 0%, #4184f9 100%);
  //         }

  //         & .item-content {
  //           min-width: 200px;
  //           min-height: 90px;
  //           max-height: 400px;
  //           display: block;
  //           position: absolute;
  //           background-color: #ffffff;
  //           color: black;
  //           z-index: 100;
  //           margin-top: 2%;
  //           margin-left: 1%;
  //           border: 1px solid #a4b4ce;
  //           box-sizing: border-box;
  //           box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  //           border-radius: 3px;
  //           text-overflow: ellipsis;
  //           overflow-y: auto;
  //           overflow-x: hidden;
  //           padding-top: 5px;

  //           & .radioflex {
  //             display: flex;
  //             font-size: 12px;
  //           }

  //           & .text-input {
  //             width: 90%;
  //           }

  //           & .chip-container {
  //             width: 300px;
  //             height: auto;
  //             overflow-y: auto;
  //             overflow-x: hidden;
  //             padding-left: 5px;

  //             & .chip {
  //               color: black;
  //               border: 1px solid;
  //               font-weight: bold;
  //               font-size: 11px;
  //               line-height: 8px;
  //               margin: 4px 2px;
  //               white-space: nowrap;
  //               border-radius: 4px;
  //               display: inline-block;
  //               cursor: pointer;

  //               &.clear {
  //                 color: #a4b4ce;
  //                 border-color: #a4b4ce;
  //                 transition: 0.4s ease-in-out;
  //               }

  //               &.full {
  //                 color: #ffffff;
  //                 background-color: #6988cd;
  //                 transition: 0.4s ease-in-out;
  //               }
  //             }

  //             & .checkbox-list {
  //               overflow-x: hidden;
  //               height: 100%;
  //               overflow-y: auto;
  //               position: absolute;
  //               width: 100%;
  //               background-color: #ffffff;
  //             }
  //           }
  //         }

  //         & .parentall {
  //           position: relative;
  //           font-weight: 700;
  //           font-size: 15px;
  //         }

  //         & .parent {
  //           position: relative;
  //           font-weight: 500;
  //           left: 10%;
  //           font-size: 13px;
  //         }

  //         & .children {
  //           position: relative;
  //           left: 20%;
  //           font-size: 11px;
  //         }

  //         & .line {
  //           border-bottom: 1px solid black;
  //         }
  //       }

  //       & .caret {
  //         background: url("/images/select-arrow.svg") no-repeat;
  //         display: block;
  //         top: 100px;
  //         width: 20px;
  //         height: 12px;
  //         transform: rotate(180deg);
  //         transition: transform 0.5s ease-in-out;
  //       }

  //       & .item-content {
  //         display: none;
  //       }
  //     }
  //   }

  //   & .item-list {
  //     width: 85%;
  //     justify-content: space-between;
  //   }
  // }

  // & .right {
  //   height: 100%;
  //   width: 24%;
  //   display: inline-flex;
  //   padding: 0px 30px;
  //   box-sizing: border-box;
  //   position: relative;
  //   top: -10px;

  //   & .icono {
  //     display: block;
  //     position: absolute;
  //     bottom: 25;
  //     transform: translateX(-100%);
  //     right: 20;
  //   }
  // }
}

.tooltip_header {
  width: 581px;

  & .tooltip_title {
    font-size: 36px;
    color: #20293d;
  }
}

.tooltip_options {
  width: 100%;
  height: 120px;

  & .tooltip_left {
    width: 48%;
    float: left;

    & .tooltip_left_hours {
      width: 100%;
      padding-bottom: 7px !important;
      margin-bottom: 5px;

      & span {
        font-size: 16px;
      }

      & .time {
        border-bottom-width: 2px;
        border-bottom-style: solid;
        border-bottom-color: rgba(32, 41, 61, 0.5);
        padding-bottom: 7px !important;

        & input {
          width: 48px !important;
          border: none !important;
          font-weight: bold !important;
          font-size: 16px;
        }
      }
    }

    & .tooltop_left_options {
      & .tooltop_left_options_checkbox {
        width: 40%;
        height: 42px;
        float: left;

        & .all-day-selector-item {
          line-height: 31px;

          & .checkmark {
            margin-top: 9px;

            &:after {
              padding-top: 0px !important;
              padding-left: 3px;
              line-height: 25px;
            }
          }
        }
      }

      & .tooltop_left_options_repeat {
        width: 50%;
        float: right;

        & .select-input {
          padding-top: 0 !important;

          & .has-input {
            padding-top: 0 !important;
          }

          & .caret {
            top: 35% !important;
          }
        }
      }
    }
  }

  & .tooltip_right {
    width: 48%;
    float: right;

    & .tooltip_all_ramps_container {
      width: 100%;
      margin-top: 12px;
    }

    & .tooltip_select_ramps_container {
      width: 100%;
      margin-top: 10px;
    }

    & img {
      position: absolute !important;
      top: 0 !important;
      left: 0 !important;
      height: 22px !important;
      width: 22px !important;
    }
  }

  & .tooltip_options_title {
    font-size: 18px;
    width: 100%;
    font-weight: bold;
  }
}

.tooltip_footer {
  & .reset {
    float: left !important;
    color: #3d77f7 !important;
  }

  & .add {
    float: right !important;
    margin-right: 15px;
    color: #3d77f7 !important;
  }

  & .save {
    float: right !important;
  }
}

.tooltip_content {
  & .tooltip_content_title_container {
    display: flex;

    & .tooltip_content_title {
      flex: 1;
      text-align: center;
      font-size: 16px;
      cursor: pointer;

      & span {
        padding: 0 5px 2px 5px;

        &.active {
          border-bottom: 5px solid #3d77f7;
        }
      }
    }
  }

  & .tooltip_content_fleets_container {
    & .tooltip_content_fleets_row_content {
      max-height: 190px;
      overflow: auto;
    }

    & .tooltip_content_fleets_row {
      display: flex;
      padding: 10px 0 10px 0;

      &.tooltip_content_row {
        border-top: 1px solid;
        border-top-color: rgba(151, 151, 151, 0.5);

        &:first-child {
          padding: 0 0 10px 0;
          border-top: 0 !important;
        }
      }

      & .tooltip_content_fleets {
        text-align: center;
        // &.tooltip_title {
        //   text-align: right;
        // }
        flex: 1;
        // margin: 0 10px 0 10px;
        font-size: 14px;
        font-weight: bold;

        & .single {
          text-align: left;

          & img {
            position: relative;
            top: 2px;
            left: 10px;
          }

          & input {
            position: relative;
            top: 1px;
            left: 15px;
          }
        }

        & .double {
          text-align: left;

          & img {
            position: relative;
            top: 2px;
            left: 5px;
          }

          & input {
            position: relative;
            top: 1px;
            left: 10px;
          }
        }

        &.force_single {
          height: 25px;
        }

        & div {
          flex-direction: column;

          & input {
            width: 30px;
            height: 20px;
            font-size: 14;
            text-align: center;
            border: none;
            border-bottom: 2px solid rgba(32, 41, 61, 0.5);
          }
        }

        & .green {
          color: #0bca88;
        }

        &:first-child {
          flex: 2 !important;

          & div {
            height: 100%;
            display: table;

            & > span {
              display: table-cell;
              vertical-align: middle;
              text-align: left;
            }
          }
        }

        & .editfleets {
          width: 15px;
          cursor: pointer;
        }

        & svg {
          margin-left: 10px;
          cursor: pointer;
        }
      }
    }
  }
}

.fleet-summary {
  & .subtitle {
    color: #afc8ff;
    font-size: 25px;
    display: flex;
    margin-bottom: 30px;
  }

  & .subtitle-subsection {
    color: #afc8ff;
    font-size: 20px !important;
    display: flex;
    // margin-bottom: 7px;
    // margin-left: 39px !important;
  }

  & .line-container {
    border-right: white 1px solid;
    border-radius: 0;
    padding-right: 3px;
    min-width: 150px;
  }

  & .total-title {
    margin-top: 20px;
    height: 78px;
  }

  // & .title-image {
  //   min-width: 200px;
  // }

  & .subtitle-white {
    color: #ffffff;
    font-size: 30px;
    display: flex;
    margin-left: 10px;
  }

  // height: 100%;
  & .fleet-summary-panel-header {
    display: inline-flex;
    color: #afc8ff;
    font-size: 16px;
    line-height: 25px;
    width: 100%;

    & > div {
      display: flex;
      margin: auto;
      align-items: center;
    }

    & .title {
      margin-left: 0;
      color: #ffffff;
      font-size: 24px;
      line-height: 37px;
    }

    & .column {
      align-content: center;
    }

    & .action {
      margin-right: 0px;
    }
  }

  & .fleet-summary-row {
    min-height: 110px;
    border-radius: 12px;
    background-color: #212a3c;
    display: flex;

    & .row-headers {
      border: solid red 1px;
      display: flex;
      padding: 30px;

      & .logo {
        height: 54px;
        width: 54px;
        color: #ffffff;
        font-size: 20px;
        line-height: 54px;
        text-align: center;
        background-color: #eb496b;
        border-radius: 27px;
      }

      & .row-header-text {
        color: #afc8ff;
        font-size: 16px;
        font-weight: bold;
        line-height: 48px;

        & .company-name {
          font-size: 24px;
          line-height: 37px;
        }
      }
    }

    & .row-content {
      border: solid blue 1px;
      display: flex;
    }
  }
}

.tooltip_equipments_list {
  max-height: 100px;
  overflow: auto;
}

.fleet-vehicle-view {
  & .valid {
    display: flex;
  }

  & .general-data {
    display: flex;
    flex-wrap: wrap;
    width: 100%;

    & .item {
      flex-basis: 32%;
    }
  }

  & .specification {
    width: 100%;

    & .specification-data {
      display: flex;
      flex-wrap: wrap;

      & .item {
        flex-basis: 32%;
      }
    }
  }

  & .normativity-table {
    width: 100%;

    & .content {
      & .normativity-item {
        & .file-selector {
          & .btn {
            width: 140px;
          }
        }
      }
    }
  }

  & .gps-provider-data {
    display: flex;
    flex-wrap: wrap;
    width: 100%;

    & .item {
      flex-basis: 48%;
    }
  }

  & .actions {
    display: flex;
    justify-content: flex-end;

    & .btn {
      margin: 10px;
    }
  }
}

.equipment-profile {
  & .lower-row {
    & .half {
      width: 50% !important;
    }

    & .full {
      width: 100% !important;
    }
  }
}

.equipment-profile,
.vehicle-profile,
.operator-profile {
  display: flex;
  padding-top: 45px;

  & .submodule-title {
    color: #afc8ff;
    font-family: Overpass;
    font-size: 16px;
    font-weight: bold;
  }

  & .left-column {
    width: 49%;
    padding-right: 2%;

    & .normativity-panel {
      padding: 0;
      margin-bottom: 20px;

      & .fleet-normativity-row {
        & .header {
          & .icon {
            width: 24px;
            height: 24px;
            margin: 20px;
          }

          & .title {
            width: calc(75% - 80px - 140px);
          }

          & .statusName {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-grow: 1;
            color: #ff4949;
          }
        }
      }
    }

    & .credentials {
      padding: 0;
      text-align: center;
      padding: 0;
      position: relative;

      & .upper-row {
        background-color: #20293d;
        border-radius: 12px 12px 0 0;
        padding-bottom: 10px;
        box-sizing: border-box;
        display: grid;

        & .profile-information {
          display: flex;
          justify-content: space-between;

          & .avatars {
            position: relative;
            left: 50%;
            top: 0;
            transform: translate(-50%, -50%);

            & img {
              height: 80px;
              width: 80px;
              border-radius: 40px;

              &.company-logo {
                height: 42px;
                width: 42px;
                position: absolute;
                top: 80;
                left: 80;
                transform: translate(-60%, -80%);
              }
            }
          }

          & .edit {
            margin-right: 20px;
            cursor: pointer;
          }
        }

        & p {
          margin: 5px 0;

          &.id {
            color: #ffffff;
            font-size: 20px;
            font-weight: bold;
          }

          &.age {
            color: #ffffff;
            font-size: 14px;
            font-weight: bold;
          }

          &.description {
            color: #afc8ff;
            font-size: 14px;
          }

          &.validated {
            font-size: 12px;
            font-weight: bold;

            &.Pendiente {
              color: #ff9800;
            }

            &.Validado {
              color: #05c985;
            }

            &.Rechazado {
              color: #ff4949;
            }

            &.Incompleto {
              color: #aaa3a3;
            }

            &.Vencido {
              color: #ff4949;
              // color: #e9c973;
            }
          }
        }
      }

      & .lower-row {
        background-color: #1a202e;
        display: flex;
        border-radius: 0 0 12px 12px;

        & > div {
          width: 33%;

          & + div {
            border-left: 1px solid rgba(255, 255, 255, 0.2);
          }

          & p {
            color: #ffffff;
            font-family: Overpass;
            font-size: 14px;

            &.submodule-title {
              color: #afc8ff;
              font-family: Overpass;
              font-size: 16px;
              font-weight: bold;
            }
          }
        }
      }

      & .lower-row-products {
        background-color: #1a202e;
        display: flex;
        border-radius: 0 0 12px 12px;

        & > div {
          width: 53%;
          padding-left: 29px;
          padding-right: 29px;

          & + div {
            border-left: 1px solid rgba(255, 255, 255, 0.2);
          }

          & p {
            text-align: left;
            color: #ffffff;
            font-family: Overpass;
            font-size: 14px;

            &.submodule-title {
              color: #afc8ff;
              font-family: Overpass;
              font-size: 16px;
              font-weight: bold;
            }
          }
        }
      }
    }

    // & .tags {
    //   & .tag {
    //     color: #fff;
    //     background-color: #6988cd;
    //     border-radius: 4px;
    //     font-size: 12px;
    //     padding: 5px;
    //     margin: 3px;
    //     white-space: nowrap;
    //   }
    // }

    & .specifications {
      padding: 0;

      & .upper-row {
        background-color: #1a202e;
        border-radius: 12px 12px 0 0;
        padding: 1px 0 0 10px;

        & img {
          width: 100%;
        }
      }

      & .lower-row {
        padding: 0 20px 10px 20px;

        & > div {
          display: flex;
          padding: 10px 0;

          & > div {
            width: 50%;
          }
        }

        & .value {
          color: #ffffff;
          font-size: 14px;
          font-weight: bold;
        }
      }
    }
  }

  & .right-column {
    width: 49%;

    & .normativity-panel {
      padding: 0;
      margin-bottom: 20px;

      & .fleet-normativity-row {
        & .header {
          & .icon {
            width: 24px;
            height: 24px;
            margin: 20px;
          }

          & .title {
            width: calc(75% - 80px - 140px);
          }

          & .statusName {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-grow: 1;
            color: #ff4949;
          }

          & .na {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-grow: 1;
            color: #ffffff;
          }
        }
      }

      // & .fleet-normativity-row{

      //   & + .fleet-normativity-row .header {
      //     border-radius: 0;
      //   }

      //   &:last-child{
      //     & .content {
      //       border-radius: 0 0 12px 12px;
      //     }
      //   }
      // }
    }

    & .gps-provider {
      display: flex;
      color: #fff;

      & > div {
        width: 50%;

        & + div {
          border-left: 1px solid rgba(255, 255, 255, 0.2);
          padding-left: 15px;
          box-sizing: border-box;
        }

        & > div {
          display: flex;

          & .logo {
            width: 30%;
            position: relative;

            & img {
              height: 50px;
              width: 50px;
              border-radius: 40px;
              position: absolute;
              top: 15px;
              left: 50%;
              transform: translateX(-50%);
            }
          }

          & .data {
            width: 70%;
          }
        }
      }
    }

    & .actions {
      display: flex;
      justify-content: flex-end;
    }
  }
}

.fleet-equipment-view {
  & .normativity-table {
    & .content {
      & .normativity-item {
        & .file-selector {
          & .btn {
            width: 140px;
          }
        }
      }
    }
  }

  & .general-data {
    display: flex;
    flex-wrap: wrap;

    & .item {
      flex-basis: 32%;
    }
  }

  & .specifications {
    width: 100%;

    & .specification-data {
      display: flex;
      flex-wrap: wrap;

      & .item {
        flex-basis: 48%;
      }

      & .radio-container {
        color: #ffffff;
        font-size: 16px;
        line-height: 25px;
        display: flex;
        align-items: flex-end;
        justify-content: center;
      }
    }
  }

  & .gps-provider-data {
    display: flex;
    flex-wrap: wrap;

    & .item {
      flex-basis: 48%;
    }
  }

  & .actions {
    display: flex;
    justify-content: flex-end;

    & .btn {
      margin: 10px;
    }
  }
}

.fleet-operator-view {
  // & .profile-image {
  //   border: solid 1px red;
  //   display: flex;
  //   width: 100%;
  //   height: 150px;

  //   & .image {}
  // }

  & .general-data {
    display: flex;
    flex-wrap: wrap;

    & .item {
      flex-basis: 32%;
    }
  }

  & .license-data {
    display: flex;
    flex-wrap: wrap;

    & .item {
      flex-basis: 48%;

      &.quarter {
        flex-basis: 24%;
      }
    }

    & .file-picker {
      display: flex;
      align-items: flex-end;

      > label {
        width: 100%;
        color: #9ba1ac;
        font-size: 15px;
        border-bottom: solid 2px #9ba1ac;
        margin-right: 15px;
        // position: absolute;
        // top: 39px;
        // left: 0;
        line-height: 14px;
        // transition: all 0.2s ease-in-out;
      }
    }

    & .radio-container {
      color: #9ba1ac;
      font-size: 16px;
      line-height: 25px;
      display: flex;
      align-items: flex-end;
      margin-left: 5px;
      margin-top: 20px;

      & .radioflex {
        padding-left: 10px;
      }
    }
  }

  & .actions {
    display: flex;
    justify-content: flex-end;

    & button {
      margin: 10px;
    }
  }

  & .normativity-table {
    width: 100%;

    & .normativity-item {
      & .btn {
        width: 130px;
      }
    }
  }
}

.register {
  background-image: url("/images/profile-bckgrnd.png"),
    linear-gradient(180deg, #6a7caf 0%, #242e42 32%);
  background-repeat: no-repeat;
  background-position: top right;
  background-size: 760px 700px, 100%;
  padding: 58px;

  &.products {
    background: linear-gradient(180deg, #6a7caf 0%, #242e42 40%);
    background-color: #242e42;
  }

  & .tags-panel {
    width: 100%;
    padding-top: 20px;

    & .text {
      color: #ffffff;
      font-size: 16px;
      line-height: 25px;
      padding-bottom: 20px;
      padding-left: 2px;
    }
  }

  & .panel {
    & .no-companies-title {
      display: flex;
      margin-left: 70px;
      margin-top: -70px;
      width: calc(100% - 70px);
      justify-content: space-between;
      font-size: 34px;
      color: white;

      & .btn {
        margin-top: 10px;
      }
    }
  }

  & .send_photo {
    color: white;
    margin-left: 60px;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    margin-bottom: 20px;

    & .optional {
      font-size: 12px;
      margin-left: 8px;
    }
  }

  & .title {
    color: #ffffff;
    font-size: 36px;
    font-weight: 800;
    line-height: 55px;
  }

  & .subtitle {
    width: 33.3%;
    color: #ffffff;
    font-size: 24px;
    font-weight: 800;
    line-height: 55px;
  }

  & .caption {
    color: #ffffff;
    font-size: 14px;
    font-weight: 600;
    margin-right: 60px;
    margin-left: 60px;

    & i {
      color: orange;
      font-style: normal;
    }
  }

  button {
    color: #ffffff;
    height: 40px;
    width: 190px;
    border: 2px;
    cursor: pointer;

    &.outline {
      color: rgb(232, 240, 255);
      border: 2px solid white;
      background: none;
    }

    &.outline:hover {
      color: #3d77f7;
      border: 2px solid white;
      background-color: white;
    }
  }

  & .logo {
    color: #fff;
    padding-bottom: 30px;
  }

  & .logo svg {
    height: 50px;
    width: 226px;
  }

  & .panel {
    padding: 0px;

    & .title {
      padding: 16px 16px 0px 16px;
    }
  }

  & .form {
    display: inline-flex;
    width: 100%;

    & .data-panel {
      display: flex;
      width: 45%;
      padding-left: 26px;
      padding-right: 50px;

      & .basic-data {
        display: flex;
        flex-wrap: wrap;

        & .full {
          width: 90%;
        }

        & .half {
          width: 44%;
        }
      }
    }

    & .data {
      width: 45%;
      padding: 0px 50px 0px 50px;
      height: auto;

      & .formDivided {
        display: inline-flex;
        width: 100%;
      }

      & .extraPadding {
        padding-bottom: 60px;
      }

      & .search-icon {
        position: absolute;
        top: 20px;
        right: 5px;
        width: 20px;
        height: 20px;
      }

      & .text-input {
        margin-left: 0px;
        color: #9ba1ac;
        font-size: 15px;
      }

      & .input-company {
        position: relative;
        width: 100%;
      }

      & .input-company .inputText:focus {
        border-color: blue;
        border-width: medium medium 2px;
      }

      & .input-company .search-floating-label {
        position: absolute;
        pointer-events: none;
        top: 15px;
        left: 0px;
        font-size: 15px;
        transition: 0.2s ease all;
      }

      & .input-company .inputText:focus ~ .search-floating-label {
        top: -5px;
        left: 0px;
        font-size: 13px;
        opacity: 1;
      }
    }

    & .picture {
      border: 1px solid #3d77f7;
      background-color: #3d77f7;
      width: 50%;
      margin: -91px -16px 0px 0px;
      border-radius: 0px 12px 12px 0px;
      // display: grid;
      justify-content: center;
      padding-top: 70px;
      padding-bottom: 30px;

      & .pictureImage {
        height: 194px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        & .pictureFrame {
          background-repeat: no-repeat;
          background-position: center;
          border: 2px dashed #5b90f7;
          border-radius: 5px;
          display: flex;
          align-items: center;
          justify-content: center;
          margin: 0 auto;
          cursor: pointer;
          width: 170px;
          height: 170px;
          object-fit: cover;
          transition: transform 0.3s ease-in-out;
          transform: scale(1, 1);
        }

        & .pictureFrameFac {
          background-repeat: no-repeat;
          background-position: center;
          border: 2px dashed #5b90f7;
          border-radius: 5px;
          display: flex;
          align-items: center;
          justify-content: center;
          margin: 0 auto;
          cursor: pointer;
          width: 170px;
          height: 170px;
          object-fit: cover;
          transition: transform 0.3s ease-in-out;
          transform: scale(1, 1);
        }

        & .pictureFrame:hover,
        & .pictureFrameFac:hover {
          transform: scale(1.1, 1.1);
        }
      }

      & .pictureImageCont {
        height: 60px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        & .pictureButton {
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }

  .companies {
    text-align: center;
    width: 100%;
    padding-bottom: 30px;

    & .error {
      margin-top: 40px;
      font-size: 20px;
      line-height: 28px;
      color: white;
    }

    & .line_seperator {
      background-color: white;
      width: 1px;
      min-width: 1px;
      height: calc(100% - 60px);
      margin-top: 30px;
      margin-bottom: 30px;
    }

    & .suggested {
      & .title-suggested-company {
        color: white;
        font-size: 22px;
      }

      & .item {
        cursor: default;
      }
    }

    & .invited {
      width: 100%;

      & .title-invited-company {
        color: white;
        font-size: 22px;
      }
    }
  }

  .companies .item {
    border-radius: 10px;
    box-sizing: border-box;
    max-width: 240px;
    padding: 20px;
    display: inline-block;
    margin: 15px 20px;
    position: relative;
    border: solid 2px transparent;
    cursor: default;
    transition: 0.4s;
  }

  .companies .item-mini {
    border-radius: 10px;
    box-sizing: border-box;
    max-width: 140px;
    margin-top: 15px;
    margin: 15px 20px;
    display: inline-table;
    position: relative;
    border: solid 2px transparent;
    transition: 0.4s;
  }

  .companies .item-mini.selected {
    border: solid 2px #79a3ff;
    background-color: #404e6a;
  }

  .companies .item-mini.selected:before {
    display: block;
    width: 36px;
    height: 36px;
    border-radius: 36px;
    box-shadow: 0 1px 2px 0 rgba(8, 236, 186, 0.28);
    background-image: linear-gradient(139deg, #2f4ff1, #4184f9);
    position: absolute;
    bottom: 0;
    right: 0;
    transform: translate(50%, 50%);
    content: "";
  }

  .companies .item-mini.selected:after {
    display: block;
    content: "";
    width: 4.5px;
    height: 10px;
    border-right: 6px solid #fff;
    border-bottom: 6px solid #fff;
    transform: rotate(45deg) translate(-50%, -50%);
    right: -5px;
    top: 100%;
    position: absolute;
  }

  .companies .item.selected {
    border: solid 2px #79a3ff;
    background-color: #404e6a;
  }

  .companies .item.selected:before {
    display: block;
    width: 48px;
    height: 48px;
    border-radius: 48px;
    box-shadow: 0 1px 2px 0 rgba(8, 236, 186, 0.28);
    background-image: linear-gradient(139deg, #2f4ff1, #4184f9);
    position: absolute;
    bottom: 0;
    right: 0;
    transform: translate(50%, 50%);
    content: "";
  }

  .companies .item.selected:after {
    display: block;
    content: "";
    width: 9px;
    height: 20px;
    border-right: 6px solid #fff;
    border-bottom: 6px solid #fff;
    transform: rotate(45deg) translate(-50%, -50%);
    right: -5px;
    top: 100%;
    position: absolute;
  }

  .companies .item img {
    display: block;
    width: 160px;
    height: 160px;
    border-radius: 160px;
    margin: 0 auto;
  }

  .companies .item-mini img {
    display: block;
    width: 60px;
    height: 60px;
    border-radius: 60px;
    margin: 0 auto;
  }

  .companies .item-mini b {
    display: block;
    text-align: center;
    font-size: 14px;
    color: #fff;
    font-weight: normal;
    line-height: 18px;
    padding: 10px 40px 0 40px;
  }

  .companies .item b {
    display: block;
    text-align: center;
    font-size: 16px;
    color: #fff;
    font-weight: normal;
    line-height: 24px;
    padding: 20px 40px 0 40px;
  }

  .companies-panel {
    width: 100%;
    padding-left: 40px;
    padding-top: 20px;
    padding-bottom: 20px;
    margin-left: 30px;
    margin-right: 30px;
    border-top: 2px solid rgba(255, 255, 255, 0.1);
    display: flex;

    & .create_company {
      color: #3d77f7;
      margin-left: 5px;
      cursor: pointer;
    }

    & p,
    & button {
      font-size: 16px;
      line-height: 28px;
      color: #fff;
    }

    & button {
      color: black;
    }
  }

  .contentButton {
    display: flex;
    justify-content: flex-end;

    & .separator {
      margin: 15px;
    }
  }
}

.fleet-tags {
  & .chip-container {
    & .title {
      padding-top: 15px;
      padding-left: 5px;
    }
  }
}

.specification-card {
  & .unit-systems {
    color: white;
    padding-right: 8px;
    padding-bottom: 5px;

    & .unitSystem {
      padding: 5px;
    }
  }
}

.specification-card {
  & .rows {
    display: flex;
    flex-direction: row;

    & .line-container-horizontal {
      margin-top: 10px;
      height: 10px;
      margin-bottom: 10px;
      display: flex;
      align-items: center;
      justify-content: center;

      & .line-vertical {
        background-color: white;
        width: 1px;
        height: 100%;
      }

      & .line-horizontal {
        background-color: white;
        width: 100%;
        height: 1px;
      }
    }

    & .line-container-vertical {
      padding-bottom: 10px;
      display: flex;
      flex-direction: column;
      align-items: center;

      & .line-vertical {
        background-color: white;
        width: 1px;
        height: 100%;
      }

      & .line-horizontal {
        background-color: white;
        width: 10px;
        height: 1px;
      }
    }
  }
}

.fleet-container {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  border-radius: 20px;

  &.fleet-column {
    flex: 0 0 calc(25% - 10px);
    color: #afc8ff;
    font-size: 16px;
    justify-content: center;
  }

  &.collapse-btn {
    width: 25px;
    height: 25px;
    margin-top: 26px;
    margin-left: 9px;
    overflow: hidden;
  }

  &.fleet-detail {
    & .total {
      width: 100%;
      text-align: center;
      color: white;
      font-size: 28px;
      margin-top: 15px;
    }

    & .catalog-header {
      width: 100%;
      text-align: center;
      color: #afc8ff;
      font-size: 14px;
      line-height: 5px;
      margin-bottom: 14px;
    }

    & .value {
      width: 100%;
      text-align: center;
      color: white;
      font-size: 20px;
    }
  }

  // &.fleet-title {}

  &.subtitle-padding {
    padding-left: 39px;
  }

  &.fleet-values-title {
    width: calc(96% - 10px);
    font-size: 15px;
    flex-direction: column-reverse;
    color: #afc8ff;
    margin-left: 10px;

    &.value {
      margin-left: 40px;
      width: 100%;
      margin-top: 7px;
    }

    &.total {
      margin-left: 40px;
      height: 90px;
      justify-content: center;
      font-size: 24px;
    }

    &.subtitle {
      margin-left: 40px;
      height: 38px;
      justify-content: center;
      font-size: 20px;
    }

    &.data-value {
      width: 100%;
      margin-bottom: 8px;
      margin-left: 70px;
    }

    &.data-value-total {
      display: flex;
      font-size: 23px;
      margin-bottom: 5px;
      height: 55px;
    }

    &.data-image {
      overflow: hidden;
      border-radius: 22.5px;
      width: 45px;
      height: 45px;
    }
  }

  &.fleet-content {
    width: calc(100% - 1px);
  }

  & .title-center-text {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
  }
}

.line {
  background-color: white;
  width: 1px;
  height: auto;
}

.license-data {
  & .file-picker {
    padding-right: 15px;
  }

  & .file-xyz {
    width: auto;
    margin-right: 15px;
  }
}

.license {
  & .license-data {
    margin-top: 0px;
    margin-bottom: 40px;
  }

  & .panel {
    margin-top: 25px;
    margin-left: -10px;
  }

  & .content {
    display: inline;
  }

  & .file-picker {
    margin-bottom: 5px;
  }
}

.logOut {
  width: 100%;
  height: 60px;
  display: flex;
  cursor: pointer;

  & .icon {
    margin-top: 20px;
    height: 20px;
    width: 24px;
    margin-left: 30px;
  }

  & .text {
    margin-top: 17px;
    margin-left: 15px;
    font-size: 18px;
    color: #657795;
  }
}

.notification {
  color: white;
  text-decoration: none;
  position: relative;
  display: inline-block;
  border-radius: 2px;
}

.notification .badge {
  position: absolute;
  top: -5px;
  right: -20px;
  padding: 5px 10px;
  border-radius: 50%;
  background: red;
  color: white;
}

.profile-information {
  & .container {
    width: 100%;
    margin-top: -40px;
    background-color: #20293d;
    z-index: 1;
    border-radius: 15px;
    overflow: hidden;
  }

  & .profile-image-container {
    background-color: #3d78f7;
    height: 100px;
    width: 100px;
    border-radius: 50px;
    margin-left: calc(50% - 50px);
    z-index: 2;
    position: relative;

    & .profile-image {
      height: 100px;
      width: 100px;
      border-radius: 50px;
    }

    & .profile-indicator {
      background-color: #6de155;
      height: 16px;
      width: 16px;
      border: 1px solid #20293d;
      border-radius: 8px;
      z-index: 2;
      position: relative;
      margin-top: -20px;
      margin-left: 70px;
    }

    & .secondary-image {
      height: 36px;
      width: 36px;
      border-radius: 18px;
      z-index: 2;
      position: relative;
      margin-top: -30px;
      margin-left: 70px;
    }
  }

  & .edit-info {
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: space-between;
    padding-top: 10px;

    & .edit-btn {
      height: 20px;
      width: 20px;
      margin-right: 20px;
      cursor: pointer;
    }

    & .edit-id {
      width: calc(50% - 30px);
      margin-left: 20px;
      color: #ffb643;
      font-size: 14px;
      font-weight: bold;
    }

    & .edit-user {
      width: calc(50% - 30px);
      margin-left: 20px;
      color: rgb(5, 201, 133);
      font-size: 12px;
    }
  }

  & .name {
    text-align: center;
    font-size: 20px;
    font-weight: bold;
    color: white;
  }

  & .birthDate {
    text-align: center;
    font-size: 13px;
    color: white;
  }

  & .alias {
    text-align: center;
    font-size: 14px;
    line-height: 22px;
    color: #afc8ff;
  }

  & .cel {
    text-align: center;
    font-size: 13px;
    line-height: 22px;
    color: #afc8ff;
  }

  & .sesion-container {
    display: flex;
    justify-content: space-between;
    padding-top: 15px;
    padding-bottom: 15px;
    align-items: center;

    & .last-sesion {
      color: #a4b4ce;
      font-size: 12px;
      margin-left: 15px;
    }

    & .connection-status {
      color: #fff;
      font-size: 12px;
      margin-right: 15px;
      width: 25%;

      & .icon-active {
        height: 12px;
        width: 12px;
        margin-right: 5px;
      }
    }
  }

  & .address-section {
    width: 100%;
    display: flex;
    justify-content: space-between;
    background-color: rgba(255, 255, 255, 0.6);
    margin-top: 10px;

    & .section {
      width: 33.3%;
      background-color: #1a202e;

      & .title {
        color: #afc8ff;
        font-size: 14px;
        margin-top: 13px;
        margin-left: 10px;
      }

      & .value {
        margin-top: 8px;
        margin-bottom: 13px;
        margin-left: 10px;
        color: white;
        font-size: 14px;
      }
    }

    & .location {
      width: calc(50% - 0.5px);
      display: flex;
      background-color: #1a202e;
      color: rgba(255, 255, 255, 0.8);
      font-size: 14px;
      line-height: 23px;
      padding-top: 18px;
      padding-bottom: 18px;

      & .icon {
        width: 20px;
        height: 20px;
        margin-left: 18px;
        margin-right: 10px;
      }
    }

    & .tags-container {
      width: calc(50% - 0.5px);
      background-color: #1a202e;
      padding-top: 10px;

      & .title {
        color: #afc8ff;
        font-size: 16px;
        font-weight: bold;
        margin-left: 18px;
      }

      & .tags {
        display: flex;
        margin-left: 18px;
        margin-bottom: 15px;
        flex-wrap: wrap;

        &.tag {
          color: #fff;
          background-color: #6988cd;
          border-radius: 4px;
          font-size: 12px;
          padding: 5px;
          margin: 3px;
          white-space: nowrap;
          display: inline-block;
        }
      }
    }
  }

  & .location-container {
    & .address {
      margin-top: 15px;
      background-color: #1a202e;
      display: flex;
      justify-content: center;
      align-items: center;
      color: rgba(255, 255, 255, 0.8);
      font-size: 14px;
      line-height: 23px;
    }

    & .email {
      width: 100%;
      font-size: 14px;
      line-height: 22px;
      color: #afc8ff;
      margin-top: 25px;
      margin-bottom: 25px;
      text-align: center;
    }

    & .icon {
      color: white;
      font-size: 30px;
      font-weight: bold;
      margin-top: -55px;
      margin-bottom: 20px;
      margin-left: calc(100% - 50px);
    }
  }

  & .facility-section {
    display: flex;

    & .email {
      font-size: 14px;
      line-height: 22px;
      color: #afc8ff;
      margin-left: 20px;
      width: calc(50% - 20px);
      margin-top: 20px;
      margin-bottom: 20px;
    }

    & .users-container {
      flex: 1;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;
      margin-right: 20px;
      color: white;
      font-size: 16px;

      & .icon {
        margin-left: 5px;
        margin-right: 5px;
      }
    }
  }

  & .validation-section {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    color: #05c985;
    font-size: 12px;

    & .icon {
      margin-left: 5px;
      margin-right: 5px;
      height: 12px;
      width: 12px;
    }
  }
}

.home {
  padding-left: 40px;
  background: linear-gradient(180deg, #6a7caf 0%, #242e42 100%) no-repeat;
  background-size: auto 470px;
  min-height: 90%;

  & .logo {
    height: 50px;
    width: 226px;
    margin-top: 40px;
    margin-bottom: 40px;
  }

  & .company-logo {
    height: 50px;
    border-radius: 50%;
    cursor: pointer;
    position: absolute;
    top: 40px;
    right: 65px;
  }

  & .welcome-title {
    font-weight: normal;
    line-height: 75px;
    font-size: 36px;
    color: #fff;
    margin-top: 10px;
  }

  & .welcome-content {
    display: flex;
    color: #ffffff;
    line-height: 25px;
    margin-bottom: 20px;
    font-size: 16px;
    font-weight: bold;
    position: relative;

    & .decoration {
      right: 80px;
      top: -40px;
      position: absolute;
    }

    & .mail {
      margin-left: 5px;
      color: rgb(179, 200, 250);
    }
  }

  & .btn-container {
    display: flex;
    margin-top: 40px;
  }

  & .point {
    width: 8px;
    height: 8px;
    background: rgb(128, 164, 248);
    margin: 8px;
  }

  & .container {
    display: flex;
    padding-left: 25px;
    margin-bottom: 35px;

    & .mail-button {
      width: auto;
      height: 40px;
      padding-right: 15px;
      padding-left: 15px;
      margin-right: 40px;
    }

    & .button {
      width: auto;
      height: 40px;
      padding-right: 15px;
      padding-left: 15px;
      margin-left: 40px;
    }

    & .company-button {
      width: auto;
      height: 40px;
      padding-right: 8px;

      & .img {
        width: 32px;
        height: 32px;
        border-radius: 16px;
        bottom: 1px;
        position: relative;
        margin-right: 5px;
      }
    }
  }
}

.tags-tab,
.expeditions-tab {
  & .header {
    display: flex;
    margin-bottom: 20px;

    & .sorts {
      width: 33%;
    }

    & .search {
      // flex-grow: 1;

      & .right {
        width: 300px;
        position: relative;

        & .icono {
          position: absolute;
          right: 0px;
          bottom: 10px;
        }
      }
    }
  }
}

.tags-content {
  // width: 200px;
  // height: 200px;
  padding-top: 5px;
  padding-bottom: 5px;

  & .title {
    font-size: 16px;
    color: #afc8ff;
    text-align: center;
    width: calc(100% - 21px);
  }

  & .edit-image {
    cursor: pointer;
    width: 20px;
    height: 20px;
    margin-right: 10px;
    margin-top: 3px;
  }

  & .line {
    width: calc(100% - 40px);
    height: 1px;
    margin-left: 20px;
    background-color: rgb(93, 97, 106);
  }

  & .container {
    // display: flex;
    // flex-wrap: wrap;
    padding-left: 20px;
    padding-right: 20px;
    overflow: hidden;
    margin-top: 10px;
    overflow-y: auto;
    max-height: 145px;

    // & .tag {
    //   background-color: rgb(110, 137, 200);
    //   color: white;
    //   font-size: 14px;
    //   padding: 4px;
    //   padding-left: 8px;
    //   padding-right: 8px;
    //   margin-right: 5px;
    //   border-radius: 4px;
    // }

    & .label {
      color: white;
      font-size: 14px;
      margin-top: 4px;
      line-height: 25px;
    }
  }
}

.text-input-invalid {
  display: flex;
  margin: -12px 0px 0px 0px;

  & .i-error {
    margin-top: -5px;
  }

  & .p-error {
    color: #fb8a8a;
    margin-left: 10px;
  }

  & + .text-input-invalid {
    margin: 0px;
    position: absolute;
    left: 0px;
    bottom: -45px;

    & p {
      margin: 0px 10px;
    }
  }
}

.checklist-tooltip {
  padding: 20px;
  font-size: 16px;
  color: white;

  & .item {
    display: flex;

    & .point {
      color: #79a3ff;
      margin-right: 10px;
    }
  }

  & .item-error {
    display: flex;
    color: rgb(255, 127, 129);

    & .point {
      margin-right: 10px;
    }
  }

  & .item-pending {
    display: flex;
    color: rgb(147, 153, 163);

    & .point {
      color: #79a3ff;
      margin-right: 10px;
    }
  }
}

.my-toast-class {
  background-color: #05c985;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
}

.my-toast-class-pending {
  background-color: #FFB302;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
}

.my-toast-class-rejected {
  background-color: #FF3838;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
}

.profile-operator-image {
  margin-top: 15px;
  margin-bottom: 5px;
  display: inline-flex;
  width: 100%;

  .inputProfileImage2 {
    display: inline-flex;
    width: 100%;

    & .pictureImage {
      height: 194px;
      width: 35%;

      & .pictureFrame {
        background-repeat: no-repeat;
        background-position: center;
        border: 2px dashed #5b90f7;
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 auto;
        cursor: pointer;
        width: 160px;
        height: 160px;
        object-fit: cover;
        transition: transform 0.3s ease-in-out;
        transform: scale(1, 1);
      }

      & .pictureFrameFac {
        background-repeat: no-repeat;
        background-position: center;
        border: 2px dashed #5b90f7;
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 auto;
        cursor: pointer;
        width: 160px;
        height: 160px;
        object-fit: cover;
        transition: transform 0.3s ease-in-out;
        transform: scale(1, 1);
      }

      & .pictureFrame:hover,
      & .pictureFrameFac:hover {
        transform: scale(1.1, 1.1);
      }
    }

    & .contentText {
      width: 65%;
      color: white;

      & .pictureButton {
        display: inline-flex;

        & .caption {
          margin-left: 25px;
          color: #8fc3e0;

          & i {
            color: white;
          }
        }
      }

      button {
        color: #ffffff;
        height: 40px;
        width: 190px;
        border: 2px;
        cursor: pointer;
        // align-self: center;

        &.outline {
          color: rgb(232, 240, 255);
          border: 2px solid white;
          background: none;
        }

        &.outline:hover {
          color: #3d77f7;
          border: 2px solid white;
          background-color: white;
        }
      }
    }
  }
}

.no-bottom-margin {
  & .message {
    margin-bottom: 10px !important;
    margin-top: 10px !important;
    display: flex;
    flex-direction: column;

    & > div {
      width: fit-content;
      align-self: center;
    }
  }
}

.no-bottom-margin-2 {
  & .message {
    margin-bottom: 10px !important;
  }
}

.modal-coupons {
  width: 60% !important;
  margin-left: -30% !important;
  & .message {
    margin-bottom: 10px !important;
  }
}

.login-view {
  background-color: #fff;
  background-image: url("/images/landing-background.png") !important;
  background-repeat: no-repeat;
  background-position-x: right;
  background-size: contain;

  & .content {
    margin: 0 auto;
    max-width: 1300px;
    position: relative;
    padding: 300px 20px;

    .indicators {
      margin-top: 15px;
      text-align: center;

      .dot {
        align-self: center;
        height: 23px;
        width: 23px;
        background-color: #bbb;
        border-radius: 50%;
        display: inline-block;
        margin-left: 15px;
        cursor: pointer;

        &.active {
          background-color: #3562f4;
        }
      }

      // .dot:hover {
      //   background-color: #aaa;
      // }
    }

    & .title {
      color: #20293d;
      font-size: 66px;
      line-height: 66px;
      margin: 0;
      padding: 0;
    }

    & .sub-title {
      font-size: 35px;
      line-height: 35px;
      color: #20293d;
      margin: 0px;
      padding: 35px 0;
    }

    & .carousel {
      margin-top: -20px;
      width: 60%;
      width: 100%;
      max-width: 700px;
      // height: 180px;
      z-index: 1;
      position: relative;

      min-width: 17em;
      height: auto;

      & .horizontalSlider___281Ls {
        height: 160px;
        outline: none;
      }

      & .panel {
        border-radius: 12px;
        background-color: #ffffff;

        & > .title {
          // font-size: 21px;
          font-size: .8em;
          line-height: 24px;
          display: flex;

          & .number {
            font-size: 64px;
            line-height: 72px;
            font-weight: bold;

            & .border {
              margin-top: 10px;
              height: 6px;
            }
          }
        }
      }
    }
  }

  & .top-container {
    margin: 0 auto;
    // max-width: 1300px;
    position: relative;
    padding: 0 20px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    & .btn {
      width: 45%;
      font-size: .9em;
      white-space: nowrap;
    }

    & .orbi-icon {
      margin-top: 20px;
      display: block;
      width: 200px;
    }

    & .login-form {
      margin-top: 10px;
      display: flex;
      width: 100%;
      max-width: 350px;
    }

    & .forgot-link {
      color: #ffb643;
      position: absolute;
      line-height: 40px;
      top: 64px;
      right: 20px;
      font-size: 13px;
      font-weight: bold;
      font-style: normal;
      cursor: pointer;
      z-index: 1;
    }
  }
}

.company-select {
  padding-left: 40px;
  background: linear-gradient(180deg, #6a7caf 0%, #242e42 100%) no-repeat;
  background-size: auto 470px;
  min-height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  & .logo {
    height: 50px;
    width: 226px;
    position: absolute;
    top: 40px;
    left: 80px;
  }

  & .welcome-content {
    margin: auto;
    width: 80%;
    text-align: center;

    & .welcome-title {
      font-weight: normal;
      line-height: 75px;
      font-weight: bold;
      font-size: 36px;
      color: #fff;
      margin-top: 10px;
    }

    & .welcome-actions {
      display: flex;
      flex-direction: row-reverse;

      .dropbtn {
        background-image: url("/images/menu/gear.svg");
        background-repeat: no-repeat;
        background-position: center;
        background-size: 30px;
        width: 160px;
        height: 60px;
        border: none;
        background-color: transparent;
      }

      .dropdown {
        position: relative;
        display: inline-block;
      }

      .dropdown-content {
        display: none;
        position: absolute;
        background-color: #f1f1f1;
        min-width: 160px;
        box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
        z-index: 1;
      }

      .dropdown-content a {
        color: black;
        padding: 12px 16px;
        text-decoration: none;
        display: block;
      }

      .dropdown-content a:hover {
        background-color: #05000000;
      }

      .dropdown:hover .dropdown-content {
        display: block;
      }

      .dropdown:hover .dropbtn {
        background-color: #75000000;
      }

      .show {
        display: block;
      }
    }

    & .companies {
      text-align: center;
      width: 100%;
      padding-bottom: 30px;

      & .item {
        border-radius: 10px;
        box-sizing: border-box;
        max-width: 240px;
        padding: 20px;
        display: inline-block;
        margin: 15px 20px;
        position: relative;
        border: solid 2px transparent;
        cursor: pointer;
        transition: 0.4s;

        & .avatar-container {
          width: 160px;
          height: 160px;
          border-radius: 160px;
          background-color: rgba(255, 255, 255, 0.98);
          position: relative;
          top: 80px;
          left: 50%;
          transform: translate(-50%, -50%);

          & img {
            // height: 30px;
            height: 85px;
            position: absolute;
            top: 47%;
            left: 50%;
            transform: translate(-50%, -50%);
          }
        }
      }

      & .companyName {
        white-space: nowrap;
      }

      & .item:hover {
        border: solid 2px #79a3ff;
        background-color: #404e6a;
      }

      & .item img {
        display: block;
        width: 160px;
        height: 160px;
        border-radius: 160px;
        margin: 0 auto;
      }

      & .item b {
        display: block;
        text-align: center;
        font-size: 16px;
        color: #fff;
        font-weight: normal;
        line-height: 24px;
        padding: 20px 40px 0 40px;
      }
    }
  }
}

.facility-schedules-ribbon {
  justify-content: space-evenly;
  margin-bottom: 30px;

  & .tab-selector-item {
    font-size: 24px;
    font-weight: bold;
    text-align: center;
    width: 10%;

    &.selected {
      border-bottom: 4px #e9643f solid;
    }
  }
}

.facility-schedules-panel {
  min-height: 600px;
  margin-bottom: -40px;

  &.anti-panel {
    background-color: transparent;
  }

  & .content {
    & .config-gear {
      cursor: pointer;
      color: #ffffff;
      font-size: 20px;
      font-weight: bold;
      line-height: 31px;
      text-align: center;
      display: flex;
      align-items: center;

      & > img {
        margin: 4px 10px;
      }
    }
  }
}

.facility-schedules-grid {
  border: solid 1px red;
}

.spaces-window-modal {
  width: 660px !important;
  margin-left: -330px !important;

  & .space-window-dialog {
    display: flex;
    flex-wrap: wrap;

    color: #20293d;
    font-size: 16px;
    line-height: 25px;

    & .header {
      color: #313c5d;
      width: 100%;

      & .title {
        font-size: 36px;
        font-weight: 900;
        line-height: 55px;
        margin-top: 0px !important;
        margin-left: 0px !important;
      }

      & .attention-span-data {
        display: flex;
        justify-content: space-between;

        & .time-block {
          & .title {
            font-size: 18px;
            line-height: 28px;
            width: 100%;
          }

          & .data {
            display: flex;
            letter-spacing: 0.33px;

            & input {
              height: 21px;
              width: 35px;
              font-size: 16px;
              font-weight: bold;
              line-height: 25px;
              text-align: center;
              margin: 0 5 0 5;
              border: none;
              border-bottom: 2px solid #20293d;
              outline: 0;
            }

            & .checkbox-container {
              margin-left: 15px;
            }
          }

          & .repeat {
            position: relative;

            & .repeat-days {
              width: 270px;
              padding: 0;

              & .caret {
                top: 40%;
              }

              & select {
                border: solid blue 1px;
                padding: 0;
                color: black;
                font-weight: 700;
              }
            }
          }

          & .tooltip-days-container {
            position: absolute;
            width: 400px;
            max-width: 400px;
            height: auto;
            max-height: 300px;
            overflow-y: auto;
            top: 100%;
            background-color: white;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            box-shadow: 0px 0px 10px 1px #20293d;
            border-radius: 12px;
            padding: 10px;

            & .close-actions {
              width: 100%;
              display: flex;
              justify-content: center;
              margin: 10px;
            }
          }
        }

        & .replicate-block {
          & .title {
            color: #293452;
            font-family: "Overpass";
            font-size: 18px;
            line-height: 28px;
            font-weight: 900;
          }

          & .spaces {
            font-size: 14px;
            letter-spacing: 0.33px;
            line-height: 25px;
            display: flex;
            flex-direction: column;
            flex-wrap: wrap;

            & .select-spaces {
              margin-top: 10px;
              display: flex;
              position: relative;
            }

            & .tooltip-spaces-container {
              z-index: 5;
              position: absolute;
              width: auto;
              max-width: 220px;
              height: auto;
              max-height: 300px;
              overflow-y: auto;
              top: 100%;
              background-color: white;
              display: flex;
              flex-wrap: wrap;
              box-shadow: 0px 0px 10px 1px #20293d;
              border-radius: 12px;
              padding: 10px;

              & .close-action {
                margin: 10px;
              }
            }
          }
        }
      }
    }

    & .capacity {
      width: 100%;

      & .division {
        width: 100%;
        height: 0px;
        // border: solid 1px black;
        margin: 14px 0px;
      }

      & .capacity-title {
        margin-top: 15px;
        padding-left: 10px;
        width: 100%;
        height: 32px;
        background-color: #dbe3f4;
        color: #293452;
        font-size: 18px;
        line-height: 32px;
        font-weight: 900;
      }

      & .capacity-header {
        display: flex;
        height: 30px;

        // & .head {
        //   width: 150px;
        // }

        & .header-content {
          width: 100%;
          display: flex;
          flex-direction: column;

          & .titles {
            color: #646d9e;
            font-size: 14px;
            font-weight: bold;
            line-height: 17px;
            text-align: center;
            width: 100%;
            height: 25px;
            display: flex;
            flex-direction: row;
          }

          & .title-icons {
            display: flex;
            flex-direction: row;
            height: 30px;

            & .actions-title {
              width: 50px;
              height: 23px;
              color: #4f5884;
              font-size: 11px;
              line-height: 12px;
              text-align: center;
            }
          }
        }
      }

      & .capacity-rows {
        overflow-y: scroll;
        max-height: 150px;

        & .row {
          display: flex;
          flex-direction: row;
          height: 35px;
          margin-bottom: 3px;
          padding-bottom: 3px;
          border-bottom: 1px solid black;

          & .equipment-inputs {
            align-items: center;

            & .classes-selector {
              position: fixed;
              z-index: 3;

              & .orbi-selector-content {
                max-height: 200px;
                overflow-y: auto;
              }
            }

            & .span-label {
              margin: 2px;
            }
          }

          & .inputs {
            // width: 100px;
            display: flex;
            flex-direction: row;
            align-items: flex-end;

            & input {
              height: 21px;
              width: 35px;
              font-size: 16px;
              font-weight: bold;
              line-height: 25px;
              text-align: center;
              margin: 0 5 0 5;
              border: none;
              border-bottom: 2px solid #20293d;
              outline: 0;
            }
          }

          & .action-inputs {
            display: flex;
            flex-direction: row;
            align-items: center;
          }
        }
      }

      & .classes {
        display: flex;
        width: 200px;
        box-sizing: border-box;
        justify-content: flex-start;
      }

      & .load {
        display: flex;
        width: 100px;
        justify-content: center;
      }

      & .unload {
        display: flex;
        width: 100px;
        justify-content: center;
      }

      & .load-unload {
        width: 100px;
        display: flex;
        justify-content: center;
      }

      & .actions {
        width: 30px;
        display: flex;
        justify-content: center;
      }
    }
  }

  & .message {
    margin-bottom: 10px !important;
  }

  /*

  style={{
            border: 'solid blue 1px',
            width: '100%',
            color: '#20293D',
            fontSize: '18px',
            lineHeight: '28px',
            fontWeight: '900',
            display: 'flex',
            flexWrap: 'wrap'*/
}

.footer-buttons-position {
  & .actions {
    flex-direction: row-reverse;
  }
}

.truck-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  color: black;

  .icon-image {
    height: 34px;
    width: 34px;
    margin: 4px;
    border-radius: 17px;
    background-color: white;
  }

  .text {
    margin: 2px;
    display: none;
  }

  .status-container {
    width: 18px;
    margin-left: -12px;
    height: 35px;

    .company {
      overflow: hidden;
      height: 1px;
      margin-top: 18px;
      width: 1px;
    }

    .status {
      overflow: hidden;
      height: 1px;
      margin-top: 18px;
      width: 1px;
    }

    .status-icon {
      width: 18px;
      height: 18px;
      border-radius: 9px;
    }
  }
}

.truck-tooltip {
  & .truck-icon {
    & .text {
      display: block;
    }

    & .icon-image {
      margin: 0px;
      margin-right: 15px;
    }

    & .status-container {
      margin-left: 1px;
      width: auto;
      height: auto;

      & .company {
        height: auto;
        margin-top: 0px;
        width: auto;
      }

      .status {
        height: 20px;
        margin-top: 0px;
        margin-left: 5px;
        width: auto;
      }
    }
  }
}

.catalog-card {
  & .__react_component_tooltip {
    &.show {
      box-shadow: none !important;
    }
  }
}

.user-info {
  width: 300px;
  min-height: 60px;
  margin: 10px;

  & .user-avatar {
    height: 50px;
    width: 50px;
    border-radius: 15px;
    margin-right: 6px;
    vertical-align: middle;
    transform: translateY(-2px);
    float: left;

    & .avatar {
      height: 50px;
      width: 50px;
      border-radius: 25px;
    }
  }

  & .user-content {
    width: 240px;
    float: right;

    & .user-title {
      text-align: left;
      font-weight: bold;
    }
  }
}

.cardToolTip {
  pointer-events: auto !important;
  border-radius: 15px !important;

  &:hover {
    visibility: visible !important;
    opacity: 1 !important;
  }
}

.cardToltipContent {
  max-height: 210px;
  overflow: auto;
}

.top-row-card {
  height: 70px !important;
}

.facility-schedules-header {
  background-color: #333d52;
  height: 50px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;

  & .maneuver-filters-container {
    margin: 5px;
    margin-left: 30px;
    border-radius: 8px;
    background-color: #485c85;

    & .maneuver-filters {
      border-radius: 8px;
      width: fit-content;
      height: 34px;
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      background-color: #212a3c;

      & .maneuver-filter-item {
        cursor: pointer;
        color: #ffffff;
        font-size: 14px;
        font-weight: bold;
        text-align: center;
        width: fit-content;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 7px;
        background-color: transparent;

        &.selected {
          background-color: #485c85;

          &:last-child {
            border-top-right-radius: 8px;
            border-bottom-right-radius: 8px;
          }

          &:first-child {
            border-top-left-radius: 8px;
            border-bottom-left-radius: 8px;
          }
        }
      }
    }
  }

  & .facility-schedules-capacity-summary {
    margin-left: 20px;
    margin-right: 20px;
    // width: 100px;
    height: 100%;
    display: flex;
    align-items: center;

    color: #ffffff;
    font-size: 18px;
    font-weight: bold;
    line-height: 31px;
    text-align: center;
  }

  & .facility-schedules-types {
    width: 150px;
    height: 100%;
    display: flex;

    align-items: center;

    & .equipment-selector {
      width: 100%;
      height: 40px;

      & .capacity-selector {
        border-radius: 6px;
        width: 133px;
        height: 100%;
        background-color: #3d5074;

        & .orbi-selector-content {
          min-width: 133px;
        }
      }
    }
  }

  & .config-gear {
    cursor: pointer;
  }

  & .default-time-selection {
    display: flex;
    align-items: center;
    color: #fff;
    font-size: 12px;

    & .default-time-selector {
      width: 50px;
      height: 40px;
      border-bottom: 2px solid #2399d2;

      & .simple-list {
        color: #000;
      }
    }
  }
}

.ribbon-container {
  width: 100%;
  background-color: #1a202e;
  display: flex;
  align-items: center;
  margin-bottom: 6px;
  margin-top: 1px;

  & .month-selector {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    height: 42px;
    width: 142px;
    border-radius: 4px;
    background-color: #2f3b52;

    opacity: 0.6;
    color: #ffffff;
    font-size: 16px;
    font-weight: bold;
  }

  & .profile-calendar-ribbon {
    width: 80%;
    height: 50px;
    margin-bottom: 5px;
    display: flex;

    // align-items: center;
    & :first-child,
    & :last-child {
      font-size: 28px;
    }

    & .tab-selector-item {
      width: 60px;
      height: 100%;
      line-height: 58px;
      margin: auto 3px;

      &.selected {
        border-bottom: #e9643f 4px solid;
      }
    }
  }
}

.middle-row-card {
  padding-bottom: 10px !important;
}

.validation-icon-container-card {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
  position: relative;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  flex-direction: column;

  & .icon-container {
    margin-top: 5px;
  }
}

.react-datepicker__header {
  & button {
    color: black;
    width: 50px;
  }

  & select {
    width: 100px;
  }
}

.slot-action-header button {
  height: 22px;
  line-height: 22px;
  width: 75px;
  font-size: 14px;
}

.contract-detail-header {
  background-color: #1a202e;
  color: #fff;
  font-weight: bold;
  border-radius: 12px 12px 0 0;

  & > div {
    display: flex;

    & > div {
      height: 66px;
      line-height: 66px;
      margin-left: 15px;

      &.transport-line {
        display: flex;

        & .avatar {
          width: 33px;
          height: 33px;
          background-size: contain;
          border-radius: 50%;
          position: relative;
          top: 50%;
          transform: translateY(-55%);
          margin-right: 10px;
        }
      }

      &.facility {
        & .logo {
          position: relative;
          top: 50%;
          transform: translateY(-80%);
          & img {
            height: 30px;
          }
        }
      }
    }
  }
}

.facility-alert-users-dialog {
  & .message {
    margin-bottom: 0 !important;
  }
  & .email-input {
    width: 100%;
    margin-top: 20px;
  }
  & .tags-container {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    max-height: 250px;
    overflow-y: auto;

    & .user-chip {
      display: inline-table;
      width: 100%;
      padding: 0;
      margin: 5px 0;
      // overflow-x: hidden;
    }
  }
}

.subtitle-fleet {
  margin-top: 20px;
  margin-left: 49px;
  color: #afc8ff;
  font-size: 20px;

  &.separate {
    margin-bottom: 20px;
  }
}

.fleet-details-subsection-titles {
  margin-bottom: 13px;
}

.invitation-modal {
  & .btn {
    margin: auto !important;
  }
}

.errorInPopup {
  padding: 5px 0 5px 0;
  color: red;
}

.text-without-arrow {
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type="number"] {
    -moz-appearance: textfield;
  }
}

.base-dialog {
  & .create-assemble-dialog {
    & .message {
      margin-bottom: 5px !important;
    }
    & .actions {
      margin-top: -8px !important;
      justify-content: flex-end;
      & .btn {
        margin: 0 5px;
      }
    }
  }
}

.attach-xls {
  display: flex;

  & > div {
    flex: 1;

    & button {
      width: 160px;
    }

    & .chip {
      background-color: white !important;
      color: black !important;
      border: none !important;
      padding: 0 !important;
      width: 300px;
    }

    // & svg {
    //   margin-top: -21px;
    // }
  }
}

.successModal {
  .successContent {
    font-weight: bold;

    & .text-group {
      display: table;
      & .image {
        width: 40px;
        vertical-align: middle;
        display: table-cell;
      }
    }

    & .errorsDetails {
      display: flex;
      flex-direction: column;
      margin-top: 15px;

      & > div {
        margin-left: 80px;
        width: 70%;
      }
    }

    & .export {
      margin-top: 15px;
    }
  }

  .successActions {
    display: flex;

    button {
      margin-left: auto;
    }
  }

  .confirmActions {
    justify-content: space-around;
    display: flex;
  }
}

.transfer-events {
  & .list {
    & .items {
      height: 90px;  
    }
  }
}

.transfer-events-container {
  & .list {
    height: 10px;
    padding-top: 0px;

    & .select-content {
      top: 15px;
    }

    & .caret {
      top: 20%;
    }

    & .items {
      height: 50px;  
    }
  }
}

.gps-units-table-view {
  width: 100%;
  height: calc(100% - 60px);

  .content-units {
    overflow-y: scroll;
    width: 100%;
    height: calc(100% - 100px);
  }

  .gps-assemble-row {
    background-color: rgb(47, 59, 81);
    height: 50;
    width: 100%;
    border-bottom-width: 1px;
    border-top-width: 0px;
    border-left-width: 0px;
    border-right-width: 0px;
    border-color: white;
    border-style: solid;

    .gps-assemble-row-container {
      width: 100%;
      display: flex;
      font-size: 13;

      .lt {
        height: 50;
        width: 10%;
        justify-content: center;
        align-items: center;
        display: flex;
      }

      .unit {
        height: 50;
        width: 23.3%;
        justify-content: center;
        align-items: center;
        display: flex;
      }

      .contract {
        height: 50;
        width: 23.3%;
        justify-content: center;
        align-items: center;
        display: flex;
      }

      .trips {
        height: 50;
        width: 19%;
        justify-content: center;
        align-items: center;
        display: flex;
      }

      .app {
        height: 50;
        width: 12.1%;
        justify-content: center;
        align-items: center;
        display: flex;
      }

      .gps {
        height: 50;
        width: 12.1%;
        justify-content: center;
        align-items: center;
        display: flex;
      }

    }

  }

  .gps-units-table-view-header {
    background-color: rgb(48, 60, 80);
    position: sticky;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1;
  }

  .gps-units-table-top-resume {
    height: 50;
    width: 100%;
    display: flex;

    .gps-units-table-top-resume-container {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: space-around;
      align-items: center;

      .gps-resume-container {
        background-color: rgb(57, 76, 117);
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: center;
      }

    }

  }

  .gps-units-table-top-title {
    background-color: rgb(57, 76, 117);
    height: 50;
    width: 100%;
    display: flex;

    .title-arrow {
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 13;
    }

  }
  
}

.map-indicators-table-view {
  width: 100%;
  height: calc(100% - 60px);

  .content-units {
    overflow-y: scroll;
    width: 100%;
    height: calc(100% - 100px);
  }

  .gps-assemble-row {
    background-color: rgb(47, 59, 81);
    height: 50;
    width: 100%;
    border-bottom-width: 1px;
    border-top-width: 0px;
    border-left-width: 0px;
    border-right-width: 0px;
    border-color: white;
    border-style: solid;

    .gps-assemble-row-container {
      width: 100%;
      display: flex;
      font-size: 13;

      .lt {
        height: 50;
        width: 10%;
        justify-content: center;
        align-items: center;
        display: flex;
      }

      .unit {
        height: 50;
        width: 26.3%;
        justify-content: center;
        align-items: center;
        display: flex;
      }

      .facility {
        height: 50;
        width: 26.3%;
        justify-content: center;
        align-items: center;
        display: flex;
      }

      .class {
        height: 50;
        width: 22%;
        justify-content: center;
        align-items: center;
        display: flex;
      }

      .eta {
        height: 50;
        width: 15.1%;
        justify-content: center;
        align-items: center;
        display: flex;
      }

    }

  }

  .map-indicators-table-view-header {
    background-color: rgb(48, 60, 80);
    // position: sticky;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1;
  }

  .map-indicators-table-top-resume {
    height: 50;
    width: 100%;
    display: flex;

    .map-indicators-table-top-resume-container {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: space-around;
      align-items: center;

      .gps-resume-container {
        background-color: rgb(57, 76, 117);
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: center;
      }

    }

  }

  .map-indicators-table-top-title {
    background-color: rgb(57, 76, 117);
    height: 50;
    width: 100%;
    display: flex;

    .title-arrow {
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 13;
    }

  }
  
}

.one-damn-tooltip {
  z-index: 2 !important;
}